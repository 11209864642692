<!--bg-->

<div class="page_bg-map_3 page_bg"></div>

<!--end bg-->

<!--Changelog-->

<div class="changelog">
  <!--page NavIndicator-->
  <ngx-scrolltop
    [backgroundColor]="'#292b40'"
    symbolColor="#17afcb"
  ></ngx-scrolltop>
  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          {{ "BREADCRUMBS.MAIN" | translate }}
        </a>
      </li>

      <li>
        <a> </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="changelog_title main_container">
    <h1>{{ "CHANGELOG.TITLE" | translate }}</h1>
    <p>{{ "CHANGELOG.CONTENT" | translate }}</p>
  </div>

  <div class="changelog_menu">
    <div class="main_container">
      <div>
        <ul>
          <li>
            <button class="global_itemFilter">
              2024
            </button>
          </li>

          <!-- <li>
            <button class="global_itemFilter">
              2022
            </button>
          </li> -->
        </ul>
      </div>

      <!-- <div class="global_searchingInput">
        <input type="text" id="global_inputSearch" placeholder="Search" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
          />
        </svg>

        <span id="global_searchingInput-loading" style="display: none;"></span>
      </div> -->
    </div>
  </div>

  <div class="main_container">
    <div
      id="changelog_2023"
      class="changelog_logs c_2023 global_list-loading global_itemToFilter"
      data-items-per-page="8"
    >
      <div *ngIf="loaded == false" class="loader space"></div>
      <div *ngIf="loaded == false" class="loader space"></div>
      <div *ngIf="loaded == false" class="loader space"></div>
      <div *ngIf="loaded == false" class="loader space"></div>
      <ul class="global_list-loading_ul">
        <li class="global_list-loading_li" *ngFor="let item of changelogDatum">
          <div
            class="changelog_logs-date active"
            (click)="toggleActiveClass($event)"
          >
            <p>
              {{
                "CHANGELOG.CHANGEPREFIX"
                  | translate
                    : {
                        changeDate:
                          item.attributes.createdAt | date: "shortDate":"UTC"
                      }
              }}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 320 512"
            >
              <path
                d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
              />
            </svg>
          </div>

          <div class="changelog_logs-list">
            <div
              class="changelog_logs-list_content"
              *ngFor="let change of item.attributes.ChangelogList"
            >
              <h1>{{ change.Type }}</h1>

              <div
                class="global_list-loading_liInside"
                *ngFor="let ch of change.Changes"
              >
                <span class="changelog_{{ ch.Category }}">{{
                  ch.Category
                }}</span>
                <p>
                  {{ ch.Description }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="global_showMore">
        <button class="global_showMore-button">
          {{ "SHOWMORE" | translate }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path
              d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
            />
          </svg>
        </button>

        <div class="global_spinner-loading" style="display: none"></div>
      </div>
    </div>

    <div
      id="changelog_2022"
      class="c_2022 global_list-loading global_itemToFilter hidden"
      data-items-per-page="8"
    >
      <div class="no-results-message" style="display: block;">
        Nothing to show here
      </div>
    </div>
  </div>
</div>

<!--end Changelog-->
