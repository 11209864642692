import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GithubReleases } from 'src/app/types/githubReleases';

@Component({
  selector: 'app-how-to-connect',
  templateUrl: './how-to-connect.component.html',
  styleUrl: './how-to-connect.component.scss',
})
export class HowToConnectComponent implements OnInit {
  private httpClient = inject(HttpClient);
  private toastr = inject(ToastrService);
  private renderer2 = inject(Renderer2);
  public downloadLink = '';

  ngOnInit(): void {
    this.httpClient
      .get(
        'https://api.github.com/repos/Duskhaven-Reforged/duskhaven-launcher-2/releases/latest',
      )
      .subscribe({
        next: (response) => {
          const releases: GithubReleases = response as any;
          const winRelease = releases.assets.find((asset) =>
            asset.name.endsWith('.msi'),
          );
          if (!winRelease) {
            this.toastr.error('Could not retrieve launcher download.', 'Error');
            return;
          }
          this.downloadLink = winRelease.browser_download_url;
        },
        error: (error) => {},
      });
  }

  downloadClient() {
    const newWindow = window.open(this.downloadLink, '_blank');
  }
}
