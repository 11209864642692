import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { DonationLog, DonationLogsResponse } from 'src/app/types/donationLogs';
import { StoreLog, StoreLogsResponse } from 'src/app/types/storeLogs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logs',
  standalone: true,
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss',
  imports: [CommonModule],
})
export class LogsComponent implements OnInit {
  private httpClient = inject(HttpClient);
  public storeLogs: StoreLog[] = [];
  public tab = 0;
  public donationLogs: DonationLog[] = [];

  ngOnInit(): void {
    this.httpClient
      .get(environment.apiUrl + '/points/store-history', {
        withCredentials: true,
      })
      .subscribe({
        next: (response) => {
          const res: StoreLogsResponse = response as any;
          this.storeLogs = res.storeLogs;
        },
        error: (error) => {
          console.log(error);
        },
      });

    this.httpClient
      .get(environment.apiUrl + '/points/donations-history', {
        withCredentials: true,
      })
      .subscribe({
        next: (response) => {
          const res: DonationLogsResponse = response as any;
          this.donationLogs = res.donationLogs;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
}
