<div class="legalWrapper">
  <h1>Refund Policy</h1>
  <h2>Duskhaven Reforged Refund Policy</h2>

  <h4>Account:</h4>
  <p>
    Refers to the combination of personal information and credentials used by
    users to access Coins, Virtual Items, and any communications systems on the
    Duskhaven Reforged website.
  </p>

  <h4>License:</h4>
  <p>
    Refers to the terms and conditions governing your use of Coins, Virtual
    Items, or Services purchased from Duskhaven Reforged.
  </p>

  <h4>Virtual Items:</h4>
  <p>
    Refers to in-game items delivered by Duskhaven Reforged in exchange for
    Coins. Coins: Refers to virtual currency made available by Duskhaven
    Reforged for purchase by Users, valid for 60 calendar days from the date of
    purchase.
  </p>

  <h4>Service:</h4>
  <p>
    Refers to any online facilities, tools, or information that Duskhaven
    Reforged makes available through the website.
  </p>

  <h4>User:</h4>
  <p>
    Refers to any third party that accesses the website and is not employed by
    Duskhaven Reforged.
  </p>

  <h4>Quality Assurance</h4>
  <p>
    Duskhaven Reforged aims to provide Virtual Items that are of high quality
    and free from defects that significantly impair the functionality or
    usability of the item. "Significant impairment" refers to issues that make
    the Virtual Item unusable for its intended purpose.
  </p>

  <h4>Scope of Refund</h4>
  <p>
    This Refund Policy covers faults that significantly impair the functionality
    or usability of the Virtual Items. It does not cover minor issues such as
    spelling errors, graphical glitches, or any other defect that does not
    significantly impair the functionality or usability of the Virtual Item.
  </p>

  <h4>Limitations on Refunds</h4>
  <p>
    Coins purchased from Duskhaven Reforged are not refundable and cannot be
    exchanged back into real-world currency.
  </p>
  <p>
    Coins must be used within 60 calendar days from the date of purchase. Unused
    Coins will expire after this period and will not be subject to refunds.
  </p>

  <h4>Technical Faults and Third-Party Payment Processors</h4>
  <p>
    Duskhaven Reforged is not responsible for technical faults originating from
    third-party payment processors. Users should direct disputes related to such
    technical faults to the respective third-party payment processor.
  </p>

  <h4>Reporting Faults</h4>
  <p>
    Users must report any faults in Virtual Items within 48 hours of discovering
    the fault. Reports can be made via the Duskhaven Reforged website or direct
    customer support channels. Failure to report within this time frame may
    result in the forfeit of a refund, at the sole discretion of Duskhaven
    Reforged.
  </p>

  <h4>Termination Rights</h4>
  <p>
    Duskhaven Reforged reserves the right to suspend or terminate the accounts
    of Users who receive refunds, to prevent abuse of the refund policy.
  </p>
</div>
