<div class="news_read-bg">
  <img
    src="https://windows11central.com/wp-content/uploads/2022/06/World-of-Warcraft-Dragonflight-se-lanzara-en-2022-los-pedidos.jpg"
    alt=""
  />
</div>

<!--News Read-->

<div class="news_read">
  <!--page NavIndicator-->

  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a routerLink="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          Main
        </a>
      </li>

      <li>
        <a routerLink="/news">
          News
        </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="main_container">
    <div class="news_read-content">
      <div class="news_read-content_topic">
        <div class="news_read-title">
          <div *ngIf="loaded == false" class="loader"></div>
          <h1>{{ blog?.data?.attributes?.Title }}</h1>

          <div>
            <span
              class="news_read-title_tag new_tag{{
                this.blog?.data?.attributes?.Category
              }}"
              >[{{ this.blog?.data?.attributes?.Category }}]</span
            >
            <span class="news_read-title_date">{{
              this.blog?.data?.attributes?.Date | date: "MM/dd/yy"
            }}</span>
            <span class="news_read-title_tagList"></span>
          </div>
        </div>

        <div class="news_read-content_topic-read">
          <div>
            <img
              src="https://api.duskhaven.net/news{{
                this.blog?.data?.attributes?.Image?.data?.attributes?.url
              }}"
              alt=""
            />
          </div>
          <div *ngIf="loaded == false" class="loader space"></div>
          <div *ngIf="loaded == false" class="loader space"></div>
          <div *ngIf="loaded == false" class="loader space"></div>
          <div *ngIf="loaded == false" class="loader space"></div>
          <markdown
            ngPreserveWhitespaces
            [data]="this.blog?.data?.attributes?.Content"
          >
          </markdown>
        </div>
      </div>

      <div class="news_read-content_menu">
        <h1>Related</h1>
        <ul>
          <li *ngFor="let item of relatedDatum">
            <a routerLink="/blog/{{ item.id }}">
              <div>
                <img
                  src="https://api.duskhaven.net/news{{
                    item.attributes.Image.data.attributes.url
                  }}"
                  alt=""
                />
              </div>

              <div>
                <p>{{ item.attributes.Title }}</p>
                <span>{{ item.attributes.Date | date: "MM/dd/yyyy" }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!--end News Read-->
