import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit, OnDestroy {
  token: string = '';
  id: string = '';

  resetForm = new FormGroup({
    newpassword: new FormControl('')
  });
  modalComponentRef:
    | ComponentRef<ErrorComponent>
    | undefined
    | ComponentRef<SuccessComponent>;

  private subs = new SubSink();
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private httpClient: HttpClient,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe((params: Params): void => {
      this.token = params['token'];
      this.id = params['id'];
    });
    this.resetForm = this.formBuilder.group({
      newpassword: ['', Validators.required]
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.viewContainerRef.remove();
  }

  showModal(innerText: string, isSuccessful: boolean) {
    this.modalComponentRef = isSuccessful
      ? this.viewContainerRef.createComponent(SuccessComponent)
      : this.viewContainerRef.createComponent(ErrorComponent);
    this.modalComponentRef.instance.text = innerText;
    this.modalComponentRef.instance.confirmAction = isSuccessful
      ? () => {
          this.router.navigate(['/login']);
        }
      : () => {
          this.modalComponentRef?.instance.closeModal();
        };
    this.subs.sink = this.modalComponentRef.instance.closed.subscribe(() => {
      this.modalComponentRef?.destroy();
    });
  }

  resetPassword() {
    if (this.resetForm.invalid) {
      return;
    } else {
      const requestBody = {
        id: this.id,
        token: this.token,
        newpassword: this.f['newpassword'].value
      };

      this.subs.sink = this.httpClient
        .post(environment.apiUrl + '/users/reset', requestBody, {
          withCredentials: true
        })
        .subscribe(
          response => {
            const res: httpSuccess = response as any;
            this.showModal('Successfully reset password', true);
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3000);
          },
          error => {
            const err: HttpErrorResponse = error;
            console.log(error);
            this.showModal(error.message, false);
          }
        );
    }
  }
}
