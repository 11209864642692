import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'home-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  title = 'Duskhaven';
  activeSpan: string = 'official';

  constructor(private el: ElementRef) {}

  setActive(span: string) {
    this.activeSpan = span;
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoWidth: false,
    navText: ['', ''],
    autoHeight: true,
    margin: 55,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    const parallaxContent = document.querySelector(
      '.main_bg'
    ) as HTMLElement | null;
    const scrollValue = window.scrollY;
    if (parallaxContent) {
      parallaxContent.style.transform = `translateY(${scrollValue * -0.02}rem)`;

      const maxOpacity = 1;
      const minScroll = 0;
      const maxScrollPercentage = 100;

      const viewportHeight = window.innerHeight;

      const maxScroll = (viewportHeight * maxScrollPercentage) / 100;

      const opacity = 1 - (scrollValue - minScroll) / (maxScroll - minScroll);

      parallaxContent.style.opacity = Math.max(
        0,
        Math.min(opacity, maxOpacity)
      ).toString();
    }
  }

  @ViewChild('container') container: ElementRef | undefined;
  @ViewChild('showMoreButton') showMoreButton: ElementRef | undefined;
  @ViewChild('container2') container2: ElementRef | undefined;
  @ViewChild('showMoreButton2') showMoreButton2: ElementRef | undefined;

  handleShowMore() {
    if (this.container != undefined && this.showMoreButton != undefined) {
      const lis = this.container.nativeElement.querySelectorAll(
        '.global_list-loading_li'
      );
      lis.forEach((li: { style: { display: string } }) => {
        li.style.display = 'block';
      });
      this.showMoreButton.nativeElement.style.display = 'none';
    }
  }

  handleShowMore2() {
    if (this.container2 != undefined && this.showMoreButton2 != undefined) {
      const lis = this.container2.nativeElement.querySelectorAll(
        '.global_list-loading_li'
      );
      lis.forEach((li: { style: { display: string } }) => {
        li.style.display = 'block';
      });
      this.showMoreButton2.nativeElement.style.display = 'none';
    }
  }

  toggleActiveClass(event: any) {
    const parentDiv = event.target.parentNode.parentElement;
    if (parentDiv.classList.contains('active')) {
      parentDiv.classList.remove('active');
    } else {
      parentDiv.classList.add('active');
    }
  }

  toggleActiveClassInfo(event: any) {
    const parentDiv = event.target.parentNode.parentElement;
    if (parentDiv.classList.contains('active')) {
      parentDiv.classList.remove('active');
      event.target.parentNode.classList.remove('active');
    } else {
      parentDiv.classList.add('active');
      event.target.parentNode.classList.add('active');
    }
  }
}
