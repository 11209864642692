import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.httpClient
      .post(
        environment.apiUrl + '/users/checklogin',
        {},
        { withCredentials: true }
      )
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          console.error('Error checking login status:', error);
          return of(false); // Explicitly return false to ensure the Observable emits a boolean value
        })
      );
  }
}
