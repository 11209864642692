<div class="page_bg-orc_1 page_bg"></div>
<div class="sign-up">
  <div class="main_container">
    <form
      class="sign_up-form global_form"
      [formGroup]="registerForm"
      (submit)="register()"
    >
      <h1>Sign Up</h1>

      <div class="global_form-input">
        <input
          type="text"
          placeholder="Login"
          formControlName="name"
          required
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
          />
        </svg>

        <span>!</span>
        <span></span>
      </div>

      <div class="global_form-input global_form-input_error">
        <input
          type="email"
          placeholder="E-mail"
          formControlName="email"
          required
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path
            d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
          />
        </svg>

        <span>!</span>
        <span></span>
      </div>

      <div class="global_form-input">
        <input
          type="password"
          placeholder="Password"
          required
          autocomplete="new-password"
          formControlName="password"
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
          />
        </svg>

        <span>!</span>
        <span></span>
      </div>

      <div class="global_form-input">
        <input
          type="password"
          placeholder="Confirm Password"
          required
          autocomplete="new-password"
          formControlName="confirmPassword"
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
          />
        </svg>

        <span>!</span>
        <span></span>
      </div>

      <div class="global_form-checkbox">
        <label for="global_form-checkbox_id">
          <input
            type="checkbox"
            id="global_form-checkbox_id"
            formControlName="acceptTerms"
          />

          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
          </span>

          <p>
            I certify that I am 14 years of age or older, I agree with the
            <a href="/" target="_blank">User Agreement</a> and
            <a href="/" target="_blank">Privacy Policy</a>
          </p>
        </label>
      </div>

      <div class="global_form-button">
        <button>Confirm</button>
      </div>

      <div class="global_form-message">
        Have an account already?
        <a routerLink="/login">Login</a>
      </div>
    </form>
  </div>
</div>
