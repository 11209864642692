import { Component } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  user = this.appState.userAccount$;
  private subs = new SubSink();

  constructor(private appState: AppStateService) {}

}
