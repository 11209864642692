import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ComponentRef,
  inject,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule
} from '@angular/forms';
import { loadScript } from '@paypal/paypal-js';
import { ToastrService } from 'ngx-toastr';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { AppStateService } from 'src/app/services/app-state.service';
import {
  DonationChoiceResponse,
  DonationChoices
} from 'src/app/types/donationChoiceResponse';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-donate',
  standalone: false,
  templateUrl: './donate.component.html',
  styleUrl: './donate.component.scss'
})
export class DonateComponent implements OnInit, OnDestroy {
  paypal: any;
  choices: DonationChoices = {};
  toastr = inject(ToastrService);

  donateForm = new FormGroup({
    choice: new FormControl(0)
  });

  private subs = new SubSink();

  constructor(
    private httpClient: HttpClient,
    private viewContainerRef: ViewContainerRef,
    private formBuilder: FormBuilder,
    private appState: AppStateService,
  ) {}

  fetchChoices() {
    this.subs.sink = this.httpClient
      .get(environment.apiUrl + '/donations/getChoices', {
        withCredentials: true
      })
      .subscribe({
        next: response => {
          const res = response as DonationChoiceResponse;
          this.choices = res.choices;
        },
        error: error => {}
      });
  }

  getSummary() {
    if (!this.f['choice'].value) {
      return 0;
    }

    return this.choices[this.f['choice'].value];
  }

  getChoices() {
    let returnChoices: { coins: number; choice: number }[] = [];

    for (const key in this.choices) {
      const value = this.choices[key];
      returnChoices.push({ coins: value, choice: parseInt(key) });
    }

    return returnChoices;
  }

  get f() {
    return this.donateForm.controls;
  }

  identify(index: any, item: any) {
    return item.choice;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.donateForm = this.formBuilder.group({
      choice: [0]
    });

    this.fetchChoices();

    this.subs.sink = this.donateForm.valueChanges.subscribe(data => {
      if (data.choice) {
        console.log('initialized');
        this.paypalInit();
      }
    });

    try {
      this.paypal = await loadScript({
        clientId: environment.paypalClientId
      });
      console.log(this.paypal);
    } catch (error) {
      console.log(error);
    }
  }

  async paypalInit() {
    const choice = this.f['choice'].value;
   
    if (!choice) {
      return;
    }
    const paypalButtonContainer = document.querySelector('#paypalButton');
    if (paypalButtonContainer) {
      paypalButtonContainer.innerHTML = ''; // Clears the inner content of the container
    }

    if (this.paypal) {
      try {
        await this.paypal
          .Buttons({
            style: {
              layout: 'horizontal',
              shape: 'rect',
              height: 40
            },
            createOrder: async() =>{
              try {
                const response = await fetch(
                  environment.apiUrl + '/donations/create',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                      choice: choice
                    })
                  }
                );

                const orderData = await response.json();
                if(orderData.error) {
                  
                }
                console.log("THE ORDERDATA", orderData);
                if (orderData.id) {
                  return orderData.id;
                } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
                // resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
              }
            },
            onApprove: async (data: { orderID: any }, actions: any) =>{
              try {
                const response = await fetch(
                  environment.apiUrl +
                    `/donations/capture?orderID=${data.orderID}`,
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ACCESS-TOKEN',
                      'PayPal-Partner-Attribution-Id': 'BN-CODE',
                      'PayPal-Auth-Assertion': 'PAYPAL-AUTH-ASSERTION'
                    },
                    credentials: 'include'
                  }
                );

                const orderData = await response.json();
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message

                const errorDetail = orderData?.details?.[0];

                if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart();
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(
                    `${errorDetail.description} (${orderData.debug_id})`
                  );
                } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData));
                } else {
                  // (3) Successful transaction -> Show confirmation or thank you message
                  // Or go to another URL:  actions.redirect('thank_you.html');
                  const transaction =
                    orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                    orderData?.purchase_units?.[0]?.payments
                      ?.authorizations?.[0];
                  console.log(
                    `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`
                  );
                  console.log(
                    'Capture result',
                    orderData,
                    JSON.stringify(orderData, null, 2)
                  );
                  this.toastr.success(`Thank you for your support! Your purchase of ${this.choices[choice]}  Dusk Coins helps Duskhaven continue to grow and improve our game and community. Enjoy!`,"Purchase Successful!")
                  this.appState.fetchUser();
                }
              } catch (error) {
                this.toastr.error('Something went wrong with your transaction. Please try again or check your payment details. If you need more help, join our Discord.','Purchase Failed!')
                console.error(error);
                console.log(
                  `Sorry, your transaction could not be processed...<br><br>${error}`
                );
              }
            }
          })
          .render('#paypalButton');
      } catch (error) {
        console.log(error);
      }
    }
  }
}
