import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Blogs, Datum } from 'src/app/types/blogs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { throwError } from 'rxjs';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent implements OnInit, OnDestroy {
  datum: Datum[] = [];
  loaded: boolean = false;
  currentCategory: string = 'All';
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoWidth: false,
    navText: ['', ''],
    autoHeight: false,
    margin: 55,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  private subs = new SubSink();
  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    let headers = new HttpHeaders().set(
      'Authorization',
      environment.bearerToken
    );
    headers.append('Origin', window.location.origin);
    headers.append('Content-Type', 'application/json');
    this.subs.sink = this.httpClient
      .get(environment.newsUrl + '/api/blogs?populate=*', {
        headers: headers,
        withCredentials: true
      })
      .subscribe({
        next: response => {
          const res: Blogs = response as any;
          this.datum = res.data;
          this.loaded = true;
        },
        error: error => {}
      });
  }

  sortArticles(): Datum[] {
    return this.datum.sort((a, b) => {
      const dateA = new Date(a.attributes.Date);
      const dateB = new Date(b.attributes.Date);
      return dateB.getTime() - dateA.getTime(); // Notice the reversed subtraction here
    });
  }

  sortFunction(Datums: Datum[]): Datum[] {
    return Datums.sort((a, b) => {
      const dateA =
        typeof a.attributes.Date === 'string'
          ? new Date(a.attributes.Date)
          : a.attributes.Date;
      const dateB =
        typeof b.attributes.Date === 'string'
          ? new Date(b.attributes.Date)
          : b.attributes.Date;
      return dateB.getTime() - dateA.getTime();
    });
  }
}
