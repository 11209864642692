<!--bg-->

<div class="page_bg-map_2 page_bg"></div>

<!--end bg-->

<div class="shop">
  <!--page NavIndicator-->

  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          Main
        </a>
      </li>

      <li class="active">
        <a>
          Shop
        </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="main_container">
    <div class="shop_special">
      <div class="shop_section-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
        >
          <path
            d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"
          />
        </svg>

        <h1>Special Offers</h1>
      </div>

      <div class="shop_special-slider">
        <div class="shop-special">
          @for (item of items.slice(0, 3); track $index) {
          <a
            [routerLink]="'/store/' + item.id"
            class="swiper-slide shop_item shop_special-item"
          >
            <div class="shop_item-img">
              <img [src]="item.image" alt="" />
            </div>

            <div class="shop_item-info">
              <span class="shop_item-info_name">
                <p>{{ item.title }}</p>
              </span>

              <span class="shop_item-info_price">
                <span>
                  D
                </span>

                <span>
                  <p>{{ item.cost }}</p>
                </span>
              </span>

              <div class="shop_item-info_tag">
                <p>{{ item.category }}</p>
              </div>
            </div>
          </a>
          }
        </div>
      </div>
    </div>

    <div class="shop_menu">
      <div>
        <p>Show:</p>

        <ul>
          <li>
            <button
              class="global_itemFilter_all "
              (click)="changeView('All')"
              [ngClass]="currentView === 'All' ? 'active' : ''"
            >
              All
            </button>
          </li>

          <li>
            <button
              class="global_itemFilter"
              (click)="changeView('Mount')"
              [ngClass]="currentView === 'Mount' ? 'active' : ''"
            >
              Mount
            </button>
          </li>

          <li>
            <button
              class="global_itemFilter"
              (click)="changeView('Transmog')"
              [ngClass]="currentView === 'Transmog' ? 'active' : ''"
            >
              Transmog
            </button>
          </li>
        </ul>
      </div>

      <div class="global_searchingInput">
        <input type="text" id="global_inputSearch" placeholder="Search" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
          />
        </svg>

        <span id="global_searchingInput-loading" style="display: none;"></span>
      </div>
    </div>

    <div
      id="shop_sectionBoost"
      class="shop_section boost global_list-loading global_itemToFilter"
      data-items-per-page="8"
      [hidden]="currentView !== 'All' && currentView !== 'Transmog'"
    >
      <div class="shop_section-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
        >
          <path
            d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"
          />
        </svg>

        <h1>Transmog</h1>
      </div>

      <ul class="global_list-loading_ul">
        <li
          class="global_list-loading_li"
          *ngFor="let item of getItemsByCategory('Transmog')"
        >
          <a [routerLink]="'/store/' + item.id">
            <div class="shop_section-item_img">
              <img src="{{ item.image }}" alt="" />
            </div>
            <div class="shop_section-item_info">
              <div>
                <p>{{ item.title }}</p>
              </div>

              <div>
                <span>D</span>
                <span>{{ item.cost }}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div
      id="shop_sectionMount"
      class="shop_section mount global_list-loading global_itemToFilter"
      data-items-per-page="8"
      [hidden]="currentView !== 'All' && currentView !== 'Mount'"
    >
      <div class="shop_section-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
        >
          <path
            d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"
          />
        </svg>

        <h1>Mount</h1>
      </div>

      <ul class="global_list-loading_ul">
        <li
          class="global_list-loading_li"
          *ngFor="let item of getItemsByCategory('Mount')"
        >
          <a [routerLink]="'/store/' + item.id">
            <div class="shop_section-item_img">
              <img src="{{ item.image }}" alt="" />
            </div>
            <div class="shop_section-item_info">
              <div>
                <p>{{ item.title }}</p>
              </div>

              <div>
                <span>D</span>
                <span>{{ item.cost }}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
