<div class="dashboard_content-title">
  <h1>Logs</h1>
</div>

<!---->

<!--Tabs-->

<div class="dashboard_content-logs_tabs">
  <button
    class="global_tabs-button"
    [ngClass]="tab === 0 ? 'active' : ''"
    (click)="tab = 0"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="18"
      viewBox="0 0 576 512"
    >
      <path
        d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
      />
    </svg>

    Order History
  </button>

  <button
    class="global_tabs-button"
    [ngClass]="tab === 1 ? 'active' : ''"
    (click)="tab = 1"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path
        d="M312 24l0 10.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3s0 0 0 0c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8l0 10.6c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-11.4c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2L264 24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5L192 512 32 512c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l36.8 0 44.9-36c22.7-18.2 50.9-28 80-28l78.3 0 16 0 64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l120.6 0 119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384c0 0 0 0 0 0l-.9 0c.3 0 .6 0 .9 0z"
      />
    </svg>

    Donation History
  </button>

  <!-- <button
    class="global_tabs-button"
    data-tab="2"
    [ngClass]="activeTab === 2 ? 'active' : ''"
    (click)="activeTab = 2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="20"
      viewBox="0 0 640 512"
    >
      <path
        d="M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"
      />
    </svg>

    IP Connections
  </button> -->
</div>

<!--end Tabs-->

<!--IP-->

<!-- <div
  id="ipConnect"
  class="dashboard_ipConnect global_tabs"
  data-tab="2"
  [ngClass]="activeTab === 2 ? 'active' : ''"
>
  <div class="dashboard_ipConnect-message">
    Do not recognize an IP?

    <div>
      If you do not recognize an IP in the logs, change your password
      immediately.
    </div>
  </div>

  <table>
    <tbody>
      <tr>
        <th>Date</th>
        <th>IP</th>
      </tr>

      <tr>
        <td>Sep 23, 2023 13:09 PM</td>
        <td>123.0.0.1</td>
      </tr>

      <tr>
        <td>Sep 23, 2023 13:09 PM</td>
        <td>123.0.0.1</td>
      </tr>

      <tr>
        <td>Sep 23, 2023 13:09 PM</td>
        <td>123.0.0.1</td>
      </tr>

      <tr>
        <td>Sep 23, 2023 13:09 PM</td>
        <td>123.0.0.1</td>
      </tr>

      <tr>
        <td>Sep 23, 2023 13:09 PM</td>
        <td>123.0.0.1</td>
      </tr>
    </tbody>
  </table>
</div> -->

<!--end IP-->

<!--Order History-->

<div
  id="orderHistory"
  class="dashboard_orderHistory global_tabs global_list-loading active"
  data-items-per-page="6"
  data-tab="1"
>
  <ul class="dashboard_orderHistory-ul global_list-loading_ul">
    <!-- <li class="dashboard_orderHistory-li global_list-loading_li">
      <div class="dashboard_orderHistory-info">
        <div class="dashboard_orderHistory-product">
          <span class="dashboard_orderHistory-product_img">
            <img src="assets/img/shop/mount/1.jpg" alt="" />
          </span>

          <div>
            <p>Dusk Coins</p>
            <p>
              Status:
              <span class="dashboard_orderHistory-rejected">Rejected</span>
              <span class="dashboard_orderHistory-rejected_message">
                ?

                <span
                  >This order got rejected because the payment method declined
                  your Credit Card</span
                >
              </span>
            </p>
          </div>
        </div>

        <div class="dashboard_orderHistory-coin">
          <div>
            <p>DC Before</p>

            <div>
              <span>D</span>
              <p>0</p>
            </div>
          </div>

          <div>
            <p>DC After</p>

            <div>
              <span>D</span>
              <p>0</p>
            </div>
          </div>
        </div>

        <div class="dashboard_orderHistory-price">2405 USD</div>
      </div>

      <div class="dashboard_orderHistory-details">
        <div>
          <p>Order ID</p>
          <span>#43430954095%$4#2</span>
        </div>

        <div>
          <p>Order Placed</p>
          <span>Sep 23, 2023 17:09 PM</span>
        </div>

        <div>
          <p>Realm</p>
          <span>Dusk</span>
        </div>

        <div>
          <p>Character</p>
          <span>DuskChar2323</span>
        </div>
      </div>
    </li> -->

    @if (tab === 0) {
      @for (log of storeLogs; track $index) {
        <li class="dashboard_orderHistory-li global_list-loading_li">
          <div class="dashboard_orderHistory-info">
            <div class="dashboard_orderHistory-product">
              <!-- <span class="dashboard_orderHistory-product_img">
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </span> -->

              <div>
                <p>{{ log.name }}</p>
                <p>
                  Status:
                  <span class="dashboard_orderHistory-completed"
                    >Completed</span
                  >
                </p>
              </div>
            </div>

            <div class="dashboard_orderHistory-coin">
              <div>
                <p>DC</p>

                <div>
                  <span>D</span>
                  <p>{{ log.cost }}</p>
                </div>
              </div>
            </div>

            <!-- <div class="dashboard_orderHistory-price">2405 USD</div> -->
          </div>

          <div class="dashboard_orderHistory-details">
            <div>
              <p>Order ID</p>
              <span>{{ log.transaction_id }}</span>
            </div>

            <div>
              <p>Order Placed</p>
              <span>{{ log.purchase_date | date: "MM/dd/yyyy HH:mm" }}</span>
            </div>

            <!-- <div>
          <p>Realm</p>
          <span>Dusk</span>
        </div>

        <div>
          <p>Character</p>
          <span>DuskChar2323</span>
        </div> -->
          </div>
        </li>
      }
    }

    @if (tab === 1) {
      @for (log of donationLogs; track $index) {
        <li class="dashboard_orderHistory-li global_list-loading_li">
          <div class="dashboard_orderHistory-info">
            <div class="dashboard_orderHistory-product">
              <!-- <span class="dashboard_orderHistory-product_img">
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </span> -->

              <div>
                <p>Donation</p>
                <p>
                  Status:
                  <span class="dashboard_orderHistory-completed">{{
                    log.transaction_status
                  }}</span>
                </p>
              </div>
            </div>

            <div class="dashboard_orderHistory-coin">
              <div>
                <p>DC</p>

                <div>
                  <span>D</span>
                  <p>{{ log.amount_DC }}</p>
                </div>
              </div>
            </div>

            <div class="dashboard_orderHistory-price">
              {{ log.amount_USD }} USD
            </div>
          </div>

          <div class="dashboard_orderHistory-details">
            <div>
              <p>Order ID</p>
              <span>{{ log.transaction_id }}</span>
            </div>

            <div>
              <p>Order Placed</p>
              <span>{{ log.time_purchased | date: "MM/dd/yyyy HH:mm" }}</span>
            </div>

            <div>
              <p>Last Updated</p>
              <span>{{ log.last_updated | date: "MM/dd/yyyy HH:mm" }}</span>
            </div>

            <!-- <div>
          <p>Realm</p>
          <span>Dusk</span>
        </div>

        <div>
          <p>Character</p>
          <span>DuskChar2323</span>
        </div> -->
          </div>
        </li>
      }
    }
  </ul>

  <div class="global_showMore">
    <button class="global_showMore-button">
      Show more
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <path
          d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
        />
      </svg>
    </button>

    <div class="global_spinner-loading" style="display: none"></div>
  </div>
</div>
