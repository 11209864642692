<!--bg-->

<div class="page_bg-map_2 page_bg"></div>

<!--end bg-->
<!--View Item-->

<div class="shop shop_view">
  <!--page NavIndicator-->

  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          Main
        </a>
      </li>

      <li>
        <a routerLink="/store">
          Shop
        </a>
      </li>

      <li routerLink="/cart">
        <a>
          Item
        </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="main_container">
    <div class="shop_view-item_description">
      <div class="shop_view-item_description-img">
        <img src="{{ item?.image }}" alt="" />
      </div>

      <div class="shop_view-item_description-text">
        <h1>Get {{ item?.title }} to your Collection!</h1>
        <p>
          {{ item?.description }}
        </p>
      </div>
    </div>

    <div class="shop_view-item_info">
      <div class="shop_view-item_info-name">
        <h1>{{ item?.title }}</h1>
        <span>{{ item?.category }}</span>
      </div>

      <!-- <div class="shop_view-item_info-complement">
        <p>This item includes:</p>

        <ul>
          <li>
            <span><img src="assets/img/shop/boost/1.jpg" alt=""/></span>
            <span>Gold Coin x2</span>
          </li>

          <li>
            <span><img src="assets/img/shop/boost/1.jpg" alt=""/></span>
            <span>Gold Coin x2</span>
          </li>

          <li>
            <span><img src="assets/img/shop/boost/1.jpg" alt=""/></span>
            <span>Gold Coin x2</span>
          </li>

          <li>
            <span><img src="assets/img/shop/boost/1.jpg" alt=""/></span>
            <span>Gold Coin x2</span>
          </li>
        </ul>
      </div> -->

      <div class="shop_view-item_info-buy">
        <p>Total:</p>

        <div class="shop_view-item_info-buy_price">
          <div>
            <span>D</span>
            <span id="shop_view-item_totalPrice">{{ calculateTotal() }}</span>
          </div>

          <div>
            <span (click)="decrementAmount()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                />
              </svg>
            </span>

            <input
              data-price="20"
              type="number"
              id="shop_view-item_quantityInput"
              min="1"
              max="10"
              [value]="amount"
              readonly
              oninput="calculateTotal()"
            />

            <span (click)="incrementAmount()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                />
              </svg>
            </span>
          </div>
        </div>

        <div class="shop_view-item_info-buy_button">
          <button (click)="addToCart()">Add to Cart</button>
        </div>
      </div>

      <div class="shop_view-item_info-msg">
        <p>
          *By buying items i our shop you automatically agree with our
          <a href="/" target="_blank">User agreement</a> and
          <a href="/" target="_blank">Financial Policy</a>
        </p>
      </div>
    </div>
  </div>

  <div class="main_container">
    <div class="shop_section">
      <div class="shop_section-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
        >
          <path
            d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"
          />
        </svg>

        <h1>Related Items</h1>
      </div>

      <ul>
        <li>
          <a href="/">
            <div class="shop_section-item_img">
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
            <div class="shop_section-item_info">
              <div>
                <p>Dog Advanced</p>
              </div>

              <div>
                <span>D</span>
                <span>20</span>
              </div>
            </div>
          </a>
        </li>

        <li>
          <a href="/">
            <div class="shop_section-item_img">
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
            <div class="shop_section-item_info">
              <div>
                <p>Dog Advanced</p>
              </div>

              <div>
                <span>D</span>
                <span>20</span>
              </div>
            </div>
          </a>
        </li>

        <li>
          <a href="/">
            <div class="shop_section-item_img">
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
            <div class="shop_section-item_info">
              <div>
                <p>Dog Ultimate</p>
              </div>

              <div>
                <span>D</span>
                <span>20</span>
              </div>
            </div>
          </a>
        </li>

        <li>
          <a href="/">
            <div class="shop_section-item_img">
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
            <div class="shop_section-item_info">
              <div>
                <p>Dog Master</p>
              </div>

              <div>
                <span>D</span>
                <span>20</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
