<div class="page_bg-map_1 page_bg"></div>

<!--Global centered Form-->

<div class="global_centered-form">
  <div class="main_container">
    <form
      class="global_form"
      [formGroup]="resetForm"
      (submit)="resetPassword()"
    >
      <div class="global_form-heading">
        <img src="assets/img/logo/logo.png" alt="" />
        <h1>Change Password</h1>
        <p>
          Enter in your new password below to change your password.
        </p>
      </div>

      <div class="global_form-input">
        <input
          type="password"
          placeholder="New Password"
          formControlName="newpassword"
          required
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
          />
        </svg>

        <span class="error">!</span>
      </div>

      <div class="global_form-button">
        <button type="submit">Send</button>
      </div>
    </form>
  </div>
</div>

<!--end Global centered Form-->
