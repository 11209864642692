import { HttpClient } from '@angular/common/http';
import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { IssueStatus } from 'src/app/types/issueStatus';
import { SingleIssue } from 'src/app/types/singleIssue';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-single-issue',
  templateUrl: './single-issue.component.html',
  styleUrl: './single-issue.component.scss'
})
export class SingleIssueComponent implements OnInit, OnDestroy {
  id: string = '';
  issue: SingleIssue | undefined;
  commentForm = new FormGroup({
    comment: new FormControl('')
  });

  public Editor = ClassicEditor;
  public comment: string = '';
  private subs = new SubSink();

  setComment({ editor }: ChangeEvent) {
    this.comment = editor.data.get();
  }

  getPriority(labels: string[]): string {
    let labelMap: Record<string, string> = {
      High: 'High',
      Low: 'Low',
      Medium: 'Medium',
      'Game Breaking': 'Game Breaking'
    };
    let label = labels.find(label => labelMap[label]);
    return label || 'Unknown';
  }

  getCategory(labels: string[]): string {
    let categoryMap: Record<string, string> = {
      Dungeons: 'Dungeons',
      Lairs: 'Lairs',
      Talents: 'Talents',
      Maps: 'Maps',
      Items: 'Items',
      Other: 'Other',
      Quests: 'Quests',
      Raids: 'Raids',
      Spells: 'Spells',
      'UI / Addons': 'UI / Addons',
      'Website & Launcher': 'Website & Launcher'
    };
    let label = labels.find(label => categoryMap[label]);
    return label || 'Unknown';
  }

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.getIssue();
    });
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
  }

  get f() {
    return this.commentForm.controls;
  }

  getIssueState(state: number): string {
    return IssueStatus[state];
  }

  submitComment() {
    if (this.comment === '') {
      return;
    }

    if (
      sessionStorage.getItem('account_id') === undefined ||
      sessionStorage.getItem('account_id') === null
    ) {
      return;
    }

    const reqBody = {
      issue: this.id,
      body: this.comment,
      userid: sessionStorage.getItem('account_id')
    };

    this.subs.sink = this.httpClient
      .post(environment.apiUrl + '/issues/makeCommentNew', reqBody, {
        withCredentials: true
      })
      .subscribe(
        response => {
          const res: httpSuccess = response as any;
          this.toastr.success('Your comment has been successfully posted.','Success!')
        },
        error => {
          this.toastr.error('Something went wrong while posting your comment. Please try again later, and ensure your display name is set in your account settings','Error!')
        }
      );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getIssue() {
    this.subs.sink = this.httpClient
      .get(environment.apiUrl + '/issues/singleNew?id=' + this.id)
      .subscribe(
        (response: unknown) => {
          this.issue = response! as any;
        },
        error => {}
      );
  }
}
