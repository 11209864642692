import { Injectable, OnDestroy, inject } from '@angular/core';
import { ILogin } from '../types/login';
import { Subject, catchError, filter, fromEvent, map, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _refresh$ = new Subject<void>();
  private httpClient = inject(HttpClient);
  private router = inject(Router);

  get refresh$() {
    return this._refresh$;
  }

  isLoggedIn() {
    return this.httpClient
      .post(
        environment.apiUrl + '/users/checklogin',
        {},
        { withCredentials: true }
      )
      .pipe(
        map(response => {
          // this.refresh$.next();
          return true;
        }),
        catchError(error => {
          // this.refresh$.next();
          return of(false);
        })
      );
  }

  logout(): void {
    this.httpClient
      .post(environment.apiUrl + '/users/logout', {}, { withCredentials: true })
      .subscribe({
        next: response => {
          sessionStorage.removeItem('account_id');
          sessionStorage.removeItem('expires');
          sessionStorage.clear();
          this.router.navigate(['/login']);
          this._refresh$.next();
        },
        error: error => {}
      });
  }

  login(account_id: number, expires: string): void {
    sessionStorage.setItem('account_id', account_id.toString());
    sessionStorage.setItem('expires', expires);
    this._refresh$.next();
  }
}
