import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {
  private httpClient = inject(HttpClient);
  private issuesPath = '/issues';
  private userIdSubject = new BehaviorSubject<string>('');
  private apiUrl = environment.apiUrl;
  constructor() {}

  setUserId(userId: string) {
    this.userIdSubject.next(userId);
  }

  getFilters() {
    return this.userIdSubject.pipe(
      switchMap(userId => {
        let params ={ 'userid': userId };
        return  this.httpClient.get(`${this.apiUrl}${this.issuesPath}/getFilters`, { params });
      })
    );
  }

  
}
