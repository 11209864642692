export const IssueStatus: Record<number, string> = {
  0: 'Open',
  1: 'Open - Confirmed',
  2: 'Open - Fix in Progress',
  3: 'Open - Testing in Progress',
  4: 'Open - Failed QA',
  5: 'Open - Staged for Live Realm',
  6: 'Closed - Fixed',
  7: 'Closed - Working as Intended',
  8: "Closed - Can't Reproduce",
  9: 'Closed - Duplicate'
};

/* 
Open - 1
Open - Confirmed - 2
Open - Fix in Progress - 3
Open - Testing in Progress - 4
Open - Failed QA - 5
Open - Staged for Live Realm - 6
Closed - Fixed - 7
Closed - Working as Intended - 8
Closed - Can't Reproduce - 9
Closed - Duplicate - 10
*/
