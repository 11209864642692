import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Changelogs, Datum } from 'src/app/types/changelogs';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrl: './changelog.component.scss'
})
export class ChangelogComponent implements OnInit, OnDestroy {
  changelogDatum: Datum[] = [];
  loaded: boolean = false;
  private subs = new SubSink();

  constructor(private httpClient: HttpClient) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    let headers = new HttpHeaders().set(
      'Authorization',
      environment.bearerToken
    );
    this.subs.sink = this.httpClient
      .get(
        environment.newsUrl +
          '/api/changelogs?populate[ChangelogList][populate]=*',
        {
          headers: headers,
          withCredentials: true
        }
      )
      .subscribe(
        response => {
          const res: Changelogs = response as any;
          this.changelogDatum = res.data;
          this.loaded = true;
        },
        error => {}
      );
  }

  toggleActiveClass(event: any) {
    event.target.classList.toggle('active');
  }
}
