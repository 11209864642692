import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  inject,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Issues } from 'src/app/types/issues';
import { Router, RouterModule } from '@angular/router';
import { Observable, Subject, debounceTime } from 'rxjs';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { httpError } from 'src/app/types/httpError';
import { IssueStatus } from 'src/app/types/issueStatus';
import { SortHeaderComponent } from './sort-header/sort-header.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { IssuesService } from 'src/app/services/issues.service';

@Component({
  selector: 'app-issues',
  standalone: true,
  templateUrl: './issues.component.html',
  styleUrl: './issues.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    SortHeaderComponent,
    NgxScrollTopModule,
    TranslateModule
  ]
})
export class IssuesComponent implements OnInit, OnDestroy {
  issues: Issues = {
    status: '',
    issues: []
  };
  filters$: Observable<any>;
  sortColumn: string = '';
  issueState = '';
  issueDateRange = '';
  sortDirection: 'DESC' | 'ASC' | '' = '';
  private issueService = inject(IssuesService);
  isLoggedIn: boolean = false;
  modalComponentRef:
    | ComponentRef<ErrorComponent>
    | undefined
    | ComponentRef<SuccessComponent>;

  currentTab: string = 'allissues';
  private _currentPage: number = 1;
  private searchParam = '';
  total = 0;
  maxPage = 0;
  private subs = new SubSink();
  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }

  sortIssues(column: string) {
    console.log(column);
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'ASC';
    }

    this.updateIssues();
  }

  getCaretIcon(column: string): 'DESC' | 'ASC' | '' {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }

    return '';
  }

  changePage(direction: 'increment' | 'decrement'): void {
    if (direction === 'increment') {
      this._currentPage++;
    } else if (direction === 'decrement') {
      this._currentPage--;
    }

    // Ensure _currentPage does not go below  1
    if (this._currentPage < 1) {
      this._currentPage = 1;
      return;
    }
    if(this._currentPage > this.maxPage) {
      this._currentPage = this.maxPage;
      return;
    }

    this.updateIssues();
  }

  onSearchInput(event: any) {
    this.currentPage = 1;
    this.searchParam = event.target.value;
    this.updateIssues();
  }

  getPriority(labels: string[]): string {
    let labelMap: Record<string, string> = {
      High: 'High',
      Low: 'Low',
      Medium: 'Medium',
      'Game Breaking': 'Game Breaking'
    };
    let label = labels.find(label => labelMap[label]);
    return label || 'Unknown';
  }

  getCategory(labels: string[]): string {
    let categoryMap: Record<string, string> = {
      Dungeons: 'Dungeons',
      Lairs: 'Lairs',
      Talents: 'Talents',
      Maps: 'Maps',
      Items: 'Items',
      Other: 'Other',
      Quests: 'Quests',
      Raids: 'Raids',
      Spells: 'Spells',
      'UI / Addons': 'UI / Addons',
      'Website & Launcher': 'Website & Launcher'
    };
    let label = labels.find(label => categoryMap[label]);
    return label || 'Unknown';
  }

  setPageTab(pageTab: string) {
    this.currentTab = pageTab;
    this.currentPage = 1;
    this.updateIssues();
  }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private viewContainerRef: ViewContainerRef
  ) { 
    this.filters$ = this.issueService.getFilters()
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('account_id') !== null) {
      this.isLoggedIn = true;
    }

    this.updateIssues();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
    }
  }

  parseIssueState(state: number): string {
    return IssueStatus[state];
  }

  setIssueState(state: string) {
    this.issueState = state;
    
  }
  getIssuesState(): string {
    return this.issueState;
  }

  sendToDisplayName() {
    this.showModal(
      'No display name set, please set one in the dashboard!',
      false,
      undefined,
      () => {
        this.router.navigate(['/dashboard']);
      }
    );
  }

  setFilter(timing: string, status: string) {
    this.setIssueState(status);
    this.setIssueDateRange(timing);
    this.updateIssues();
  }

  getIssueDateRange(): string {
    return this.issueDateRange 
  }

  setIssueDateRange(timing: any) {
    this.issueDateRange = timing 
  }

  showModal(
    innerText: string,
    isSuccessful: boolean,
    issueId?: number,
    func?: () => void
  ) {
    this.modalComponentRef = isSuccessful
      ? this.viewContainerRef.createComponent(SuccessComponent)
      : this.viewContainerRef.createComponent(ErrorComponent);
    this.modalComponentRef.instance.text = innerText;
    this.modalComponentRef.instance.confirmAction = isSuccessful
      ? () => {
          this.router.navigate(['/issues/' + issueId]);
        }
      : () => {
          if (func) func();
          else this.modalComponentRef?.instance.closeModal();
        };
    this.subs.sink = this.modalComponentRef.instance.closed.subscribe(() => {
      this.modalComponentRef?.destroy();
    });
  }

  updateIssues() {
    let params =
      '?pageNumber=' + this.currentPage 
    if(this.getIssuesState()) {
      params += `&state=${this.getIssuesState()}`
    }
    if(this.getIssueDateRange()) {
      params += `&range=${this.getIssueDateRange()}`
    }
    if (this.searchParam && this.searchParam !== '')
      params += `&searchParams=${this.searchParam}`;

    if (
      this.currentTab === 'myissues' ||
      this.currentTab === 'myclosedissues'
    ) {
      if(sessionStorage.getItem('account_id')) {
        this.issueService.setUserId(sessionStorage.getItem('account_id')!);
        params += `&userid=${sessionStorage.getItem('account_id')}`;
      }
    }
    else {
      this.issueService.setUserId('');
    }
    console.log(this.sortColumn)

    if (this.sortColumn !== '') {
      params += `&sortBy=${this.sortColumn}&sortOrder=${this.sortDirection}`;
    }

    this.subs.sink = this.httpClient
      .get(environment.apiUrl + '/issues/getAllNew' + params)
      .subscribe(
        (response: unknown) => {
          const res: Issues = response as any;
          this.issues = { status: '', issues: [] };
          this.issues = { ...res };
          this.total = res.total || 0;
          this.maxPage = Math.ceil((res.total || 1) / 15)
        },
        (error: HttpErrorResponse) => {
          const err: HttpErrorResponse = error;
          console.log(err);
          let msg = err.message;
          if (err.status === 401) {
            const e: httpError = err.error;
            msg = e.message;
          }
          if (msg === 'No display name set') {
            this.sendToDisplayName();
          } else {
            this.showModal(msg, false);
          }
        }
      );
  }
}
