<div class="page_bg-customs page_bg"></div>

<div class="customs">
  <!--Custom Heading-->
  <ngx-scrolltop
  [backgroundColor]="'#292b40'"
  symbolColor="#17afcb"
  ></ngx-scrolltop>
  <div class="customs_heading">
    <div class="main_container">
      <h1>A New Experience</h1>
      <p>
        Duskhaven introduces exclusive custom features that will make your
        gameplay more fun and dynamic!
      </p>
    </div>

    <div class="main_container">
      <div class="customs_sliderHeadings">
        <p>Our main custom features are:</p>
        <div class="customs_navigation">
          <div
            class="swiper-button-prev customs_heading-slider_prev"
            (click)="customSlider.prev()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 320 512"
            >
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
              />
            </svg>
          </div>

          <div
            class="swiper-button-next customs_heading-slider_next"
            (click)="customSlider.next()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 320 512"
            >
              <path
                d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="customs_heading-slider">
        <owl-carousel-o [options]="customOptions" #customSlider>
          <!-- <div class="swiper-wrapper"> -->
          <ng-template carouselSlide>
            <a
              [routerLink]="['/custom']"
              fragment="races"
              target="_self"
              class="swiper-slide"
            >
              New Playable Races

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </a>
          </ng-template>
          <ng-template carouselSlide>
            <a
              [routerLink]="['/custom']"
              fragment="combination"
              class="swiper-slide"
            >
              Class Combination
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg> </a
          ></ng-template>

          <ng-template carouselSlide>
            <a
            [routerLink]="['/custom']"
            fragment="mythic"
            class="swiper-slide"
            >
              Mythic Dungeons
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg> </a
          ></ng-template>

          <ng-template carouselSlide
            ><a
              [routerLink]="['/custom']"
              fragment="raids"
              class="swiper-slide"
            >
              Custom & Reworked Raids

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg> </a
          ></ng-template>

          <ng-template carouselSlide>
            <a
              [routerLink]="['/custom']"
              fragment="lair"
              class="swiper-slide"
            >
              Lairs
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg> </a
          ></ng-template>
          <ng-template carouselSlide>
            <a
              [routerLink]="['/custom']"
              fragment="hardcore"
              class="swiper-slide"
            >
              Hardcore Mode

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </a>
          </ng-template>
          <ng-template carouselSlide>
            <a [routerLink]="['/custom']" fragment="pvp" class="swiper-slide">
              PvP

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </a>
          </ng-template>
          <!-- </div> -->
        </owl-carousel-o>
        <!--


        </div> -->
      </div>
    </div>
  </div>

  <!--end Custom Heading-->

  <!--Races-->

  <div
    id="races"
    class="customs_content customs_content-withTab global_tabs-container"
    data-tabset="races"
  >
    <div class="customs_content-title main_container">
      <span>I</span>
      <h1>New Playable Races</h1>
    </div>

    <div class="customs_content-withTab_tabs">
      <div class="main_container">
        <span
          class="global_tabs-button"
          [ngClass]="raceTab === 1 ? 'active' : ''"
          data-tab="1"
          (click)="raceTab = 1"
          >Goblin</span
        >
        <span
          class="global_tabs-button"
          data-tab="2"
          [ngClass]="raceTab === 2 ? 'active' : ''"
          (click)="raceTab = 2"
          >Worgen</span
        >
        <span
          class="global_tabs-button tba"
          data-tab="3"
          >[ More to be announced ]</span
        >
      </div>
    </div>

    <div
      class="customs_content-withTab_content global_tabs race_custom bg_cover"
      data-tab="1"
      [ngClass]="raceTab === 1 ? 'active' : ''"
    >
      <div class="main_container">
        <div>
          <h1>Goblin</h1>
          <p>Play as a wily, cunning race of traders and tinkers.</p>
          <!-- <a
            href="https://www.youtube.com/watch?v=iQkwYB5B3Es"
            data-fancybox="race"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
              />
            </svg>
          </a> -->
        </div>
      </div>

      <div class="newRace_img">
        <img src="assets/img/customs/character/goblin.png" alt="" />
      </div>
    </div>

    <div
      class="customs_content-withTab_content global_tabs race_custom bg_cover"
      data-tab="2"
      [ngClass]="raceTab === 2 ? 'active' : ''"
    >
      <div class="main_container">
        <div>
          <h1>Worgen</h1>
          <p>Play as a Gilnean Worgen, once human but afflicted with the Worgen curse, never able to retain a human form again.</p>
          <!-- <a
            href="https://www.youtube.com/watch?v=iQkwYB5B3Es"
            data-fancybox="race"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
              />
            </svg>
          </a> -->
        </div>
      </div>

      <div class="newRace_img">
        <img src="assets/img/customs/character/worgen.png" alt="" />
      </div>
    </div>

    <div
      class="customs_content-withTab_content global_tabs race_custom bg_cover"
      data-tab="3"
      [ngClass]="raceTab === 3 ? 'active' : ''"
    >
      <div class="main_container">
        <div>
          <h1>GodLike Human</h1>
          <p>Feel the strength of a Warrior with the bast abilities of a Elf</p>

          <a
            href="https://www.youtube.com/watch?v=iQkwYB5B3Es"
            data-fancybox="race"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
              />
            </svg>
          </a>
        </div>
      </div>

      <div class="newRace_img">
        <img src="assets/img/customs/character/hybrid_elf.png" alt="" />
      </div>
    </div>
  </div>

  <!--end Races-->

  <!--Combinations-->

  <div
    id="combination"
    class="customs_content customs_content-withTab global_tabs-container"
    data-tabset="combination"
  >
    <div class="customs_content-title main_container">
      <span>II</span>
      <h1>Races & Class Combination</h1>
    </div>

    <div class="customs_content-withTab_tabs">
      <div class="main_container">
        <span
          class="global_tabs-button"
          data-tab="1"
          [ngClass]="classTab === 1 ? 'active' : ''"
          (click)="classTab = 1"
          >Alliance</span
        >
        <span
          class="global_tabs-button"
          data-tab="2"
          [ngClass]="classTab === 2 ? 'active' : ''"
          (click)="classTab = 2"
          >Horde</span
        >
      </div>
    </div>

    <!--Alliance-->

    <div
      class="customs_content-withTab_content combination_alliance global_tabs bg_cover "
      data-tab="1"
      [ngClass]="classTab === 1 ? 'active' : ''"
    >
      <div class="customs_content-withTab_content-table main_container">
        <h1>Alliance Races & Classes Combinations</h1>

        <!--ul Table-->

        <ul>
          <!--th-->

          <li class="combination_th">
            <p>Races</p>

            <div>
              <img src="assets/img/customs/class/1.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/2.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/3.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/4.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/5.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/6.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/7.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/8.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/9.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/10.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/11.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/12.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/1.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/1.png" alt="" />

              <span>
                Class Name
              </span>
            </div>
          </li>

          <!--end th-->

          <!--td-->

          <li class="combination_td">
            <p>Dwarf</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Worgen</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Draenei</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Human</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Gnome</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Night Elf</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Pandaren</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Dark Iron Dwarf</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->

          <!--td-->

          <li class="combination_td">
            <p>Lightforged Draenei</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>
          </li>

          <!--end td-->
        </ul>
      </div>
    </div>

    <!--end Alliance-->

    <!--Horder-->

    <div
      class="customs_content-withTab_content combination_horder global_tabs bg_cover"
      data-tab="2"
      [ngClass]="classTab === 2 ? 'active' : ''"
    >
      <div class="customs_content-withTab_content-table main_container">
        <h1>Horde Races & Classes Combinations</h1>

        <!--ul Table-->

        <ul>
          <!--th-->

          <li class="combination_th">
            <p>Races</p>

            <div>
              <img src="assets/img/customs/class/1.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/2.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/3.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/4.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/5.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/6.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/7.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/8.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/9.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/10.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/11.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/12.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/1.png" alt="" />

              <span>
                Class Name
              </span>
            </div>

            <div>
              <img src="assets/img/customs/class/1.png" alt="" />

              <span>
                Class Name
              </span>
            </div>
          </li>

          <!--end th-->

          <!--td-->

          <li class="combination_td">
            <p>Dwarf</p>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
            </div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>

            <div></div>
          </li>

          <!--end td-->
        </ul>
      </div>
    </div>

    <!--end Horder-->
  </div>

  <!--end Combinations-->

  <!--Mythic-->

  <div
    id="mythic"
    class="customs_content customs_content-withList mythic_custom custom_noBorder bg_cover"
  >
    <div class="customs_content-title main_container">
      <span>III</span>
      <h1>Mythic Dungeon</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. In assumenda
        dolor nulla enim voluptates distinctio harum, dignissimos obcaecati
        voluptatem soluta eveniet odio ratione rerum quis neque. Ducimus sed
        natus voluptates?
      </p>
    </div>

    <ul class="main_container">
      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>
    </ul>
  </div>

  <!--end Mythic-->

  <!--Raids-->

  <div
    id="raids"
    class="customs_content customs_content-withTab global_tabs-container"
    data-tabset="races"
  >
    <div class="customs_content-title main_container">
      <span>IV</span>
      <h1>Custom & Reworked Raids</h1>
    </div>

    <div class="customs_content-withTab_tabs">
      <div class="main_container">
        <span
          class="global_tabs-button"
          data-tab="1"
          [ngClass]="raidTab === 1 ? 'active' : ''"
          (click)="raidTab = 1"
          >The Scarlet Rebellio</span
        >
        <span
          class="global_tabs-button"
          data-tab="2"
          [ngClass]="raidTab === 2 ? 'active' : ''"
          (click)="raidTab = 2"
          >Gnoll Raid</span
        >
        <span class="global_tabs-button tba" data-tab="3"
          >[ To be announced ]</span
        >
      </div>
    </div>

    <div
      class="customs_content-withTab_content global_tabs raid_custom-scarlet bg_cover"
      data-tab="1"
      [ngClass]="raidTab === 1 ? 'active' : ''"
    >
      <div class="main_container">
        <div>
          <h1>The Scarlet Rebellio</h1>
          <p>Feel the strength of a Warrior with the bast abilities of a Elf</p>

          <a
            href="https://www.youtube.com/watch?v=iQkwYB5B3Es"
            data-fancybox="race"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div
      class="customs_content-withTab_content global_tabs raid_custom-gnoll bg_cover"
      data-tab="2"
      [ngClass]="raidTab === 2 ? 'active' : ''"
    >
      <div class="main_container">
        <div>
          <h1>Gnoll Raid</h1>
          <p>Feel the strength of a Warrior with the bast abilities of a Elf</p>

          <a
            href="https://www.youtube.com/watch?v=iQkwYB5B3Es"
            data-fancybox="race"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--end Raids-->

  <!--Lairs-->

  <div
    id="lair"
    class="customs_content customs_content-withList lairs_custom bg_cover"
  >
    <div class="customs_content-title main_container">
      <span>V</span>
      <h1>Lairs</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum tenetur
        odit nulla facere unde cupiditate quam nesciunt quos? Accusamus
        voluptate nesciunt perferendis nihil obcaecati unde ut eum molestias
        facilis quam!
      </p>
    </div>

    <ul class="main_container">
      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>
    </ul>
  </div>

  <!--end Lairs-->

  <!--Hardcore-->

  <div
    id="hardcore"
    class="customs_content customs_content-withList hardcore_custom bg_cover"
  >
    <div class="customs_content-title main_container">
      <span>VI</span>
      <h1>Hardcore</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut esse quidem
        hic quia, iste consequatur animi quasi facilis voluptate dolore
        laboriosam vero ullam veritatis amet dignissimos quibusdam excepturi
        natus quae!
      </p>
    </div>

    <ul class="main_container">
      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>
    </ul>
  </div>

  <!--end Hardcore-->

  <!--PvP-->

  <div
    id="pvp"
    class="customs_content customs_content-withList pvp_custom bg_cover"
  >
    <div class="customs_content-title main_container">
      <span>VII</span>
      <h1>PvP</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        dolorum facilis eveniet enim doloremque repellat optio soluta ipsam
        laudantium odit quidem iusto culpa, nulla magnam dolores ex ipsum totam
        nobis.
      </p>
    </div>

    <ul class="main_container">
      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>

      <li>
        <div>
          <img src="assets/img/features/1_thumb.png" alt="" />
        </div>
        <div>
          <h1>The Mythic</h1>
          <p>
            The mythic dungeon is a mythic dunogen, that's is mythic in another
            level
          </p>
        </div>
      </li>
    </ul>
  </div>

  <!--end PvP-->
</div>

<!--end Customs-->
