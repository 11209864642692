import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Blog } from 'src/app/types/blog';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { Blogs, Datum } from 'src/app/types/blogs';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [CommonModule, MarkdownModule, RouterModule],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss',
  providers: [provideMarkdown()]
})
export class BlogComponent implements OnInit, OnDestroy {
  id: string = '';
  private routeSub!: Subscription;
  blog: Blog | undefined;
  relatedDatum: Datum[] = [];
  loaded: boolean = false;
  private subs = new SubSink();
  constructor(private route: ActivatedRoute, private httpClient: HttpClient) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.sink = this.routeSub = this.route.params.subscribe(
      (params: Params): void => {
        this.id = params['id'];
        this.getContent();
      }
    );
  }

  getContent() {
    let headers = new HttpHeaders().set(
      'Authorization',
      environment.bearerToken
    );
    headers.append('Origin', 'https://duskhaven.net');
    headers.append('Content-Type', 'application/json');
    this.subs.sink = this.httpClient
      .get(environment.newsUrl + '/api/blogs/' + this.id + '?populate=*', {
        headers: headers,
        withCredentials: true
      })
      .subscribe(
        response => {
          const res: Blog = response as any;
          this.blog = res;
          this.loaded = true;
        },
        error => {}
      );

    this.subs.sink = this.httpClient
      .get(
        environment.newsUrl +
          '/api/blogs?populate=*&pagination[start]=0&pagination[limit]=4',
        { headers: headers, withCredentials: true }
      )
      .subscribe(
        response => {
          const res: Blogs = response as any;
          this.relatedDatum = res.data;
        },
        error => {}
      );
  }
}
