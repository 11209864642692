import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrl: './activate.component.scss'
})
export class ActivateComponent implements OnInit, OnDestroy {
  email: string = '';
  private routeSub!: Subscription;
  private subs = new SubSink();
  modalComponentRef:
    | ComponentRef<ErrorComponent>
    | undefined
    | ComponentRef<SuccessComponent>;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.routeSub = this.route.params.subscribe(
      (params: Params): void => {
        this.email = params['email'];

        if (this.email !== '') {
          this.activateAccount();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.viewContainerRef.remove();
  }

  showModal(innerText: string, isSuccessful: boolean) {
    this.modalComponentRef = isSuccessful
      ? this.viewContainerRef.createComponent(SuccessComponent)
      : this.viewContainerRef.createComponent(ErrorComponent);
    this.modalComponentRef.instance.text = innerText;
    this.modalComponentRef.instance.confirmAction = isSuccessful
      ? () => {
          this.router.navigate(['/login']);
        }
      : () => {
          this.modalComponentRef?.instance.closeModal();
        };
    this.subs.sink = this.modalComponentRef.instance.closed.subscribe(() => {
      this.modalComponentRef?.destroy();
    });
  }

  activateAccount() {
    const requestBody = {
      email: this.email
    };

    this.subs.sink = this.httpClient
      .post(environment.apiUrl + '/users/activate', requestBody, {
        withCredentials: true
      })
      .subscribe(
        response => {
          const res: httpSuccess = response as any;
          this.showModal('Successfully activated account', true);
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        },
        error => {
          const err: HttpErrorResponse = error;
          console.log(error);
          this.showModal(error.message, false);
        }
      );
  }
}
