import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrl: './custom.component.scss'
})
export class CustomComponent {
  raceTab: number = 1;
  classTab: number = 1;
  raidTab: number = 1;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ['', ''],
    autoHeight: true,
    margin: 55,
    responsive: {
      0: {
        items: 2,
        margin: 10
      },
      400: {
        items: 3
      },
      740: {
        items: 1
      },
      940: {
        items: 5
      }
    },
    nav: false
  };
}
