import { Component } from '@angular/core';

@Component({
  selector: 'app-refundpolicy',
  templateUrl: './refundpolicy.component.html',
  styleUrls: ['./refundpolicy.component.scss']
})
export class RefundpolicyComponent {

}
