import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from 'src/app/services/app-state.service';
import { httpError } from 'src/app/types/httpError';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { AuthService } from '../../services/auth.service';
import { ILoginResponse } from '../../types/loginResponse';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });
  mustRedirect = false;

  isButtonDisabled: boolean = false;

  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private appState: AppStateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('redirect') === 'forums') {
        this.mustRedirect = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    if (this.isButtonDisabled) {
      return;
    }

    this.isButtonDisabled = true;
    if (this.loginForm.invalid) {
      this.isButtonDisabled = false;
      return;
    } else {
      const requestBody = {
        username: this.f['username'].value,
        password: this.f['password'].value
      };

      this.subs.sink = this.httpClient
        .post(environment.apiUrl + '/users/login', requestBody, {
          withCredentials: true
        })
        .subscribe(
          response => {
            const res: ILoginResponse = response as any;
            this.authService.login(
              res.session.user.account_id,
              res.session.cookie.expires
            );

            if (this.mustRedirect && res.hasDisplayName) {
              window.location.replace('https://forums.duskhaven.net');
            }

            if (this.mustRedirect && res.hasDisplayName === false) {
              this.toastr.success('You are now logged in.',"Login Successful!")
              this.router.navigate(['/dashboard/setDisplayName']);
              this.isButtonDisabled = false;
              return;
            }
            this.appState.fetchUser();
            this.toastr.success('You are now logged in.',"Login Successful!")
            this.router.navigate(['/dashboard']);
            this.isButtonDisabled = false;

            if (this.mustRedirect && res.hasDisplayName) {
              window.location.replace('https://forums.duskhaven.net');
            }
          },
          error => {
            const err: HttpErrorResponse = error;
            let msg = err.message;
            if (err.status === 401) {
              const e: httpError = err.error;
              msg = e.message;
            }
            this.isButtonDisabled = false;
            this.toastr.error('The username or password you\'ve entered is incorrect. Please try again.',"Login Failed!")
          }
        );
    }
  }
}
