<!--bg-->

<div class="page_bg-map_1 page_bg"></div>

<!--end bg-->

<!--Global centered Form-->

<div class="global_centered-form">
  <div class="main_container">
    <form class="global_form">
      <div class="global_form-heading">
        <img src="assets/img/logo/logo_favicon.png" alt="" />
        <h1>Play now</h1>
        <p>
          Our launcher will automatically update your client and enable you to
          connect to our servers.
        </p>
        <div class="global_form-button">
          <button class="downloadButton" (click)="downloadClient()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"
              />
            </svg>
            Download
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!--end Global centered Form-->
