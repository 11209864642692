import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Timelines } from 'src/app/types/timelines';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent implements OnInit, OnDestroy {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ['', ''],
    autoHeight: false,
    margin: 0,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  timelines: Timelines | undefined = undefined;
  private subs = new SubSink();

  constructor(private httpClient: HttpClient) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  hasPassed(date: string): boolean {
    const currentDate = new Date();
    const suppliedDate = new Date(date);
    return suppliedDate.getTime() <= currentDate.getTime();
  }

  calculateDifference(date: string): string {
    const currentDate = new Date();
    const suppliedDate = new Date(date);

    let diffYears = 0;
    let diffMonths = 0;
    let diffDays = 0;

    // Calculate the difference in years, months, and days
    if (suppliedDate.getTime() > currentDate.getTime()) {
      // Supplied date is in the future
      diffYears = suppliedDate.getFullYear() - currentDate.getFullYear();
      diffMonths = suppliedDate.getMonth() - currentDate.getMonth();
      diffDays = suppliedDate.getDate() - currentDate.getDate();
    } else {
      // Supplied date is in the past
      diffYears = currentDate.getFullYear() - suppliedDate.getFullYear();
      diffMonths = currentDate.getMonth() - suppliedDate.getMonth();
      diffDays = currentDate.getDate() - suppliedDate.getDate();
    }

    // Adjust for negative values
    if (diffMonths < 0 || (diffMonths === 0 && diffDays < 0)) {
      diffYears--;
      diffMonths += 12;
    }
    if (diffDays < 0) {
      diffMonths--;
      diffDays += 30; // Approximate average month length
    }

    // Determine what to display
    if (suppliedDate.getTime() > currentDate.getTime()) {
      // Supplied date is in the future
      if (diffYears >= 1) {
        return `Releases in ${diffYears} years`;
      } else if (diffMonths >= 1) {
        return `Releases in ${diffMonths} months`;
      } else {
        return `Releases in ${diffDays} days`;
      }
    } else {
      // Supplied date is in the past
      if (diffYears >= 1) {
        return `Released ${diffYears} years ago`;
      } else if (diffMonths >= 1) {
        return `Released ${diffMonths} months ago`;
      } else {
        return `Released ${diffDays} days ago`;
      }
    }
  }

  ngOnInit(): void {
    let headers = new HttpHeaders().set(
      'Authorization',
      environment.bearerToken
    );
    headers.append('Content-Type', 'application/json');
    this.subs.sink = this.httpClient
      .get(environment.newsUrl + '/api/timelines?populate=*', {
        headers: headers,
        withCredentials: true
      })
      .subscribe(
        response => {
          const res: Timelines = response as any;
          this.timelines = res;
        },
        error => {}
      );
  }
}
