<div class="page_bg-orc_2 page_bg"></div>

<div class="dashboard">
  <!--page NavIndicator-->

  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          Main
        </a>
      </li>

      <li class="active">
        <a> Dashboard </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="main_container">
    <!--Dashboard Menu-->

    <div class="dashboard_menu-mobile" onclick="toggleClass('dashboard_menu')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <path
          d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
        />
      </svg>
    </div>

    <ul class="dashboard_menu">
      <div
        class="dashboard_menu-mobile"
        onclick="toggleClass('dashboard_menu')"
        style="top: 2rem; right: 2.5rem"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 384 512"
        >
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          />
        </svg>
      </div>

      <li [ngClass]="selectedOption === 'account' ? 'active' : ''">
        <a (click)="showComponent('account')">
          General

          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 320 512"
          >
            <path
              d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
            />
          </svg>
        </a>
      </li>

      <li [ngClass]="selectedOption === 'logs' ? 'active' : ''">
        <a (click)="showComponent('logs')">
          Logs

          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 320 512"
          >
            <path
              d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
            />
          </svg>
        </a>
      </li>
      <!--
      <li>
        <a routerLink="dashboard">
          Characters

          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 320 512"
          >
            <path
              d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
            />
          </svg>
        </a>
      </li> -->

      <li>
        <button (click)="logout()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"
            />
          </svg>

          Logout
        </button>
      </li>
    </ul>

    <!--end Dashboard Menu-->

    <!--Dashboard Content-->

    <div class="dashboard_content">
      @if (selectedOption === "logs") {
        <app-logs class="logsWrapper" />
      }

      @if (selectedOption === "account") {
        <div class="dashboard_content-title">
          <h1>General</h1>
        </div>
        <div
          class="dashboard_content-wrapper dashboard_content-noList dashboard_content-profile"
        >
          <div class="dashboard_content-profile_avatarProfile">
            <span>
              <img src="assets/img/user/avatar/1.png" alt="" />
            </span>

            <span>
              <h1>{{ user()?.username }}</h1>
              <p>{{ user()?.email }}</p>
            </span>
          </div>
          <!--
        <div class="dashboard_content-profile_avatarButton">

          <button>Edit Avatar</button>
        </div>
        --></div>

        <!--end Avatar-->
        <div class="dashboard_content-wrapper dashboard_content-withList">
          <div class="dashboard_content-withList_title">Account</div>

          <ul class="dashboard_content-withList_list">
            <li>
              <div class="global_toggleable">
                <span>Username</span>
                <span>
                  <p>{{ user()?.username }}</p>
                </span>
              </div>

              <form>
                <p>Change your Name:</p>

                <div class="global_form-input">
                  <input type="text" placeholder="Name" required />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                    />
                  </svg>

                  <span class="error">!</span>
                </div>

                <button>Change</button>
              </form>
            </li>

            <li [ngClass]="{ 'error-border': mustSetDisplayName === true }">
              <div
                class="global_toggleable"
                [ngClass]="{
                  active: userDropDown === true,
                }"
                (click)="userDropDown = !userDropDown"
              >
                <span>Display Name</span>
                <span>
                  <p>{{ user()?.displayName }}</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                    >
                      <path
                        d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      />
                    </svg>
                  </span>
                </span>
              </div>

              <form [formGroup]="displayNameForm" (submit)="setDisplayName()">
                <p>Change your Display Name:</p>

                <div class="global_form-input">
                  <input
                    type="text"
                    placeholder="Name"
                    formControlName="displayName"
                    maxlength="12"
                    required
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                    />
                  </svg>

                  <span class="error">!</span>
                </div>

                <button type="submit">Change</button>
              </form>
            </li>

            <li>
              <div class="global_toggleable">
                <span>E-mail</span>
                <span>
                  <p>{{ user()?.email }}</p>
                </span>
              </div>

              <form>
                <p>Change your E-mail:</p>

                <div class="global_form-input">
                  <input type="text" placeholder="E-mail" required />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
                    />
                  </svg>

                  <span class="error">!</span>
                </div>

                <button>Change</button>
              </form>
            </li>

            <li>
              <div class="global_toggleable" routerLink="/forgot">
                <span>Password</span>
                <span>
                  <p style="transform: translateY(0.2rem)">*************</p>

                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                    >
                      <path
                        d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      />
                    </svg>
                  </span>
                </span>
              </div>

              <form>
                <p>Change your Password:</p>

                <div class="global_form-input">
                  <input
                    type="text"
                    placeholder="Actual Password"
                    required
                    autocomplete="new-password"
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
                    />
                  </svg>

                  <span class="error">!</span>
                </div>

                <div class="global_form-input">
                  <input
                    type="text"
                    placeholder="New Password"
                    required
                    autocomplete="new-password"
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
                    />
                  </svg>

                  <span class="error">!</span>
                </div>

                <div class="global_form-input global_form-input_error">
                  <input
                    type="text"
                    placeholder="Confirm New Password"
                    required
                    autocomplete="new-password"
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
                    />
                  </svg>

                  <span class="error">!</span>
                </div>

                <button>Change</button>
              </form>
            </li>

            <li>
              <div class="dashboard_content-withList_list-gold">
                <span>Dusk Coins</span>
                <span>
                  <p>{{ user()?.donation_points }}</p>

                  <a href="/donate">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      />
                    </svg>
                  </a>
                </span>
              </div>
            </li>
          </ul>
        </div>
      }

      <!--end Account-->

      <!--Connections

      <div class="dashboard_content-wrapper dashboard_content-withList">
        <div class="dashboard_content-withList_title">
          Account Connections
        </div>

        <ul class="dashboard_content-withList_list">
          <li>
            <div>
              <span>Google</span>
              <span>
                <button>Connect</button>
              </span>
            </div>
          </li>

          <li>
            <div>
              <span>Facebook</span>
              <span>
                <button class="dashboard_red-button">Disconnect</button>
              </span>
            </div>
          </li>

          <li>
            <div>
              <span>Apple ID</span>
              <span>
                <button class="dashboard_red-button">Disconnect</button>
              </span>
            </div>
          </li>
        </ul>
      </div>


      end Connections-->

      <!--Delete

      <div
        class="dashboard_content-wrapper dashboard_content-noList dashboard_content-warning"
      >
        <h1>Delete my account</h1>
        <p>This action can not be undone</p>
        <button class="dashboard_red-button">Delete</button>
      </div>

      end Delete-->
    </div>

    <!--end Dashboard Content-->
  </div>
</div>
