<div class="legalWrapper">
  <h1>Privacy Policy</h1>
  <h2>Duskhaven Privacy Policy</h2>

  <h4>User:</h4>
  <p>
    Any individual who visits or engages with the Duskhaven website or
    game.
  </p>

  <h4>Member:</h4>
  <p>A User who has created an account with Duskhaven.</p>

  <h4>Personal Information:</h4>
  <p>
    Information that can be used to identify an individual, either alone or in
    combination with other information.
  </p>

  <h4>Gameplay Data:</h4>
  <p>
    Data related to in-game actions, player statistics, and other behavioral
    data. Cookie: Small data files stored on your device by your web browser
    upon visiting our website.
  </p>

  <h4>Introduction</h4>
  <p>
    This Privacy Policy is intended to inform Users and Members about the
    collection, use, and protection of your Personal Information on Duskhaven
    Reforged. As a non-profit educational project, we are committed to
    responsible data management and adhere to U.S. privacy laws.
  </p>

  <h4>Information We Collect</h4>

  <h4>Account Information:</h4>
  <p>
    Includes username, password, and email address used for account creation and
    identification.
  </p>

  <h4>Gameplay Data:</h4>
  <p>
    In-game activities, progress, and interactions to improve gameplay and user
    experience.
  </p>

  <h4>Technical Information:</h4>
  <p>
    IP address, device type, and other data for technical functionalities. When
    and How We Collect Information
  </p>

  <h4>Upon Registration:</h4>
  <p>When you create an account or update your profile.</p>

  <h4>Automatically:</h4>
  <p>
    Through Gameplay Data and Technical Information during your use of our
    services.
  </p>

  <h4>Customer Support:</h4>
  <p>When you contact us for help or inquiries.</p>

  <h4>Use of Information</h4>
  <p>We use the collected information for:</p>

  <h4>Service Operations:</h4>
  <p>To provide, maintain, and improve our services.</p>

  <h4>Analysis:</h4>
  <p>To evaluate user engagement and improve gaming features.</p>

  <h4>Communication:</h4>
  <p>
    To send important notices, updates, and other types of messages. How We
    Protect Your Information
  </p>

  <h4>Encryption:</h4>
  <p>
    All sensitive data is encrypted using Secure Socket Layer (SSL) technology.
    Limited Access: Only designated personnel have access to databases
    containing Personal Information.
  </p>

  <h4>Security Audits:</h4>
  <p>Regular checks for security vulnerabilities and improvements.</p>

  <h4>Cookie Policy</h4>
  <p>We use cookies for:</p>

  <h4>Authentication:</h4>
  <p>To recognize you during your session.</p>

  <h4>User Preferences:</h4>
  <p>To remember settings and other customizations.</p>
  <p>
    Cookies can be disabled through your browser settings, although this may
    affect website functionality.
  </p>

  <h4>User Access and Control</h4>
  <p>You have the right to:</p>

  <h4>Access:</h4>
  <p>View the Personal Information we hold about you.</p>

  <h4>Correction:</h4>
  <p>Update incorrect or incomplete data.</p>
  <p>
    To exercise these rights, you can contact us as described in the "Contact
    Us" section.
  </p>

  <h4>Third-Party Disclosure</h4>
  <p>
    We do not sell, trade, or transfer your Personal Information to external
    parties, unless required by law.
  </p>

  <h4>Data Retention</h4>
  <p>
    We keep your Personal Information for as long as your account is active or
    as necessary for the provision of our services.
  </p>

  <h4>Policy Changes</h4>
  <p>
    We reserve the right to update this Privacy Policy at any time. Changes will
    be notified through this page or via email.
  </p>

  <h4>Contact Us</h4>
  <p>
    For questions regarding this Privacy Policy, you can reach out to us through
    the available options on our website or on our Discord.
  </p>
</div>
