<div class="page_bg-map_4 page_bg"></div>
<div class="news">
  <!--page NavIndicator-->
  <ngx-scrolltop
    [backgroundColor]="'#292b40'"
    symbolColor="#ffd95a"
  ></ngx-scrolltop>
  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          {{ "BREADCRUMBS.MAIN" | translate }}
        </a>
      </li>

      <li>
        <a> </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="news_title main_container">
    <h1>{{ "BREADCRUMBS.NEWS" | translate }}</h1>
  </div>

  <div class="news_mainNews">
    <div class="main_container news_mosaic">
      <!-- routerLink="/blog/{{ item.id }}"  -->

      <a
        class="mosaic_item"
        *ngFor="let item of sortFunction(datum) | slice: 0:5; let i = index"
        [ngClass]="i === 0 ? 'enlarge' : ''"
        routerLink="/blog/{{ item.id }}"
      >
        <div>
          <img
            src="https://api.duskhaven.net/news{{
              item.attributes.Image.data.attributes.url
            }}"
            alt=""
          />
        </div>

        <div>
          <h1>
            <span class="new_tag{{ item.attributes.Category }}">
              [{{ item.attributes.Category }}]
            </span>
            {{ item.attributes.Title }}
          </h1>

          <span>
            {{ item.attributes.Date | date: "MM/dd/yyyy" }}
          </span>
        </div>
      </a>
    </div>
  </div>

  <div class="news_menu">
    <div class="main_container">
      <div>
        <ul>
          <li>
            <a
              [ngClass]="currentCategory === 'All' ? 'active' : ''"
              (click)="currentCategory = 'All'"
              >{{ "NEWS.CATEGORIES.ALL" | translate }}</a
            >
          </li>

          <li>
            <a
              [ngClass]="currentCategory === 'Event' ? 'active' : ''"
              (click)="currentCategory = 'Event'"
              >{{ "NEWS.CATEGORIES.EVENT" | translate }}</a
            >
          </li>

          <li>
            <a
              [ngClass]="currentCategory === 'Update' ? 'active' : ''"
              (click)="currentCategory = 'Update'"
              >{{ "NEWS.CATEGORIES.UPDATE" | translate }}</a
            >
          </li>

          <li>
            <a
              [ngClass]="currentCategory === 'Dev Blog' ? 'active' : ''"
              (click)="currentCategory = 'Dev Blog'"
              >{{ "NEWS.CATEGORIES.DEVBLOG" | translate }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="news_list main_container">
    <div
      id="news_listMain"
      class="news_list-news global_list-loading"
      data-items-per-page="4"
    >
      <ul class="global_list-loading_ul">
        <div *ngIf="loaded == false" class="loader"></div>
        <div *ngIf="loaded == false" class="loader"></div>
        <div *ngIf="loaded == false" class="loader"></div>
        <div *ngIf="loaded == false" class="loader"></div>
        <li
          class="global_list-loading_li update"
          *ngFor="let item of sortArticles()"
        >
          <a
            routerLink="/blog/{{ item.id }}"
            *ngIf="
              currentCategory === item.attributes.Category ||
              currentCategory === 'All'
            "
          >
            <div>
              <img
                src="https://api.duskhaven.net/news{{
                  item.attributes.Image.data.attributes.url
                }}"
                alt=""
              />
            </div>

            <div>
              <span>
                <h1>
                  <span class="new_tag{{ item.attributes.Category }}"
                    >[{{ item.attributes.Category }}]</span
                  >{{ item.attributes.Title }}
                </h1>
                <p>{{ item.attributes.Subtitle }}</p>
              </span>

              <span>
                {{ item.attributes.Date | date: "MM/dd/yyyy" }}
              </span>
            </div>
          </a>
        </li>
      </ul>

      <div class="global_showMore">
        <button class="global_showMore-button">
          Show more
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path
              d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
            />
          </svg>
        </button>

        <div class="global_spinner-loading" style="display: none"></div>
      </div>
    </div>
  </div>
</div>
