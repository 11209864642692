<!--bg-->

<div class="page_bg-map_1 page_bg"></div>

<!--end bg-->

<!--Global centered Form-->

<div class="global_centered-form">
  <div class="main_container">
    <form class="global_form" [formGroup]="loginForm" (submit)="login()">
      <div class="global_form-heading">
        <img src="assets/img/logo/logo.png" alt="" />
        <h1>Duskhaven Login</h1>
      </div>

      <div class="global_form-input"
      [ngClass]="{
        'global_form-input_error':
        loginForm.get('username')?.errors &&
        loginForm.get('username')?.dirty
      }"
      >
        <input
          type="text"
          placeholder="Username"
          formControlName="username"
          name="username"
          required
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
          />
        </svg>

        <span class="error">!</span>
      </div>

      <div class="global_form-input"
      [ngClass]="{
        'global_form-input_error':
        loginForm.get('password')?.errors &&
        loginForm.get('password')?.dirty
      }">
        <input
          type="password"
          placeholder="Password"
          required
          name="password"
          formControlName="password"
          autocomplete="new-password"
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
          />
        </svg>

        <span class="error">!</span>
      </div>

      <!-- <div class="global_form-checkbox">
        <label for="global_form-checkbox_id">
          <input type="checkbox" id="global_form-checkbox_id" />

          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
          </span>

          <p>Keep me logged in</p>
        </label>
      </div> -->

      <div class="global_form-button">
        <button
          type="submit"
          [className]="isButtonDisabled ? 'disabled' : ''"
          [disabled]="isButtonDisabled"
        >
          Confirm
        </button>
      </div>

      <div class="global_form-message">
        Forgot your account?
        <a routerLink="/forgot">Recover</a>
      </div>
    </form>
  </div>
</div>

<!--end Global centered Form-->
