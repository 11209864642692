import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-sort-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sort-header.component.html',
  styleUrl: './sort-header.component.scss'
})

export class SortHeaderComponent {
  @Input() columnName!: string;
  @Input() sortColumn!: string;
  @Input() sortDirection!: 'ASC' | 'DESC' | '';
  @Output() sortChange = new EventEmitter<string>();
  internalName: string = '';

  constructor() {}

  onSort() {
    this.sortChange.emit(this.columnName.replace(" ", "_"));
  }

  getCaretIcon(): string {
    const internalName = this.columnName.replace(" ", "_")
    if (this.sortColumn === internalName) {
      return this.sortDirection === 'ASC' ? 'ASC' : 'DESC';
    }
    return '';
  }

}
