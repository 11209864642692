<div class="page_bg-map_3_single_issue page_bg"></div>
<div class="news">
  <!--page NavIndicator-->
  <ngx-scrolltop
    [backgroundColor]="'#292b40'"
    symbolColor="#17afcb"
  ></ngx-scrolltop>
  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          Main
        </a>
      </li>

      <li>
        <a routerLink="/issues">Issues</a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="news_title main_container" *ngIf="this.issue">
    <h1>
      {{ this.issue.issue.Subject }}
      <label
        class="issue_prio issue_prio--{{
          this.issue.issue.Priority | lowercase
        }}"
        >{{ this.issue.issue.Priority }}</label
      >
    </h1>
    <h3>Category - {{ this.issue.issue.Category }}</h3>
  </div>

  <div class="issueGrid" *ngIf="this.issue">
    <div class="quarterCol row-1 issueBox issueCenter">
      <h3>Status</h3>
      <p>{{ getIssueState(this.issue.issue.Status) }}</p>
    </div>
    <div class="quarterCol row-1 issueBox issueCenter">
      <h3>Creator</h3>
      <p>{{ this.issue.issue.displayName }}</p>
    </div>
    <div class="smallCol row-1 issueBox issueCenter">
      <h3>Created</h3>
      <p>{{ this.issue.issue.Created_At | date: "MM/dd/yyyy" }}</p>
    </div>
    <div class="smallCol row-1 issueBox issueCenter">
      <h3>Last Updated</h3>
      <p>{{ this.issue.issue.Last_Updated | date: "MM/dd/yyyy" }}</p>
    </div>
    @if (this.issue.issue.Affected_ID) {
    <div class="fullCol row-1--large issueBox descBox description">
      <h3>Affected ID</h3>
      <p>{{ this.issue.issue.Affected_ID }}</p>
    </div>
    }
    <div class="fullCol row-3--large issueBox descBox description">
      <h3>Description</h3>
      <markdown>
        {{ this.issue!.issue.Body }}
      </markdown>
    </div>
    <h3 class="commentDivider">Comments</h3>

    <div
      class="fullCol row-3 issueBox descBox"
      *ngFor="let comment of issue.comments"
      [class.devcomment]="comment.isGM"
    >
      <p class="user">
        {{ comment.comment.displayName }} &nbsp;
        <span class="commentDate">
          {{ comment.comment.Created | date: "LLL dd, yyyy hh:mm aa" }}</span
        >
      </p>
      <markdown [data]="comment.comment.Comment" ngPreserveWhitespaces>
      </markdown>
    </div>
    <div class="commentsInput">
      <form [formGroup]="commentForm" (submit)="submitComment()">
        <!-- <textarea formControlName="comment"></textarea> -->
        <ckeditor [editor]="Editor" (change)="setComment($event)"></ckeditor
        ><button type="submit" class="submitBtn">Submit</button>
      </form>
    </div>
  </div>
</div>
