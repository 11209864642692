<div class="main_bg">
  <div class="main_bg-effect"></div>
</div>

<!--end dragon bg-->

<!--end Global Nav-->

<!--Header-->
<ngx-scrolltop
  [backgroundColor]="'#292b40'"
  symbolColor="#ffd95a"
></ngx-scrolltop>
<div class="main_header">
  <div class="main_container">
    <div class="main_header-logo">
      <img src="assets/img/logo/logo_3d-effect.webp" alt="" />
    </div>

    <div class="main_header-slogan">
      <h1></h1>
    </div>

    <div class="main_header-link">
      <a [routerLink]="['/how-to-connect']">
        <span>
          {{ "HOME.PLAY_NOW" | translate }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"
            />
          </svg>
        </span>

        <span><img src="assets/img/main_button/frame.png" alt="" /></span>
        <span
          ><img src="assets/img/main_button/orange_light.png" alt=""
        /></span>
        <span><img src="assets/img/main_button/bg.png" alt="" /></span>
      </a>
    </div>
  </div>
</div>

<!--end Header-->

<!--History-->

<div class="main_history">
  <div class="main_container">
    <!--Title-->

    <div class="main_history-title">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <path
          d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
        />
      </svg>

      <h1>{{ "HOME.HISTORY.TITLE" | translate }}</h1>
    </div>

    <!---->

    <!--Content-->

    <div class="main_history-content">
      <!--Old-->

      <div
        class="main_history-content_wrapper history_old main_history-expand_old active"
      >
        <div>
          <img src="assets/img/history/old_thumb.webp" alt="" />
        </div>

        <div>
          <h1>{{ "HOME.HISTORY.ITEM1TITLE" | translate }}</h1>
          <p>
            {{ "HOME.HISTORY.ITEM1CONTENT" | translate }}
          </p>
        </div>
      </div>

      <!---->

      <!--New-->

      <div
        class="main_history-content_wrapper history_new main_history-expand_new active"
      >
        <div>
          <img src="assets/img/video/official/dusklogo.webp" alt="" />
        </div>

        <div>
          <h1>{{ "HOME.HISTORY.ITEM2TITLE" | translate }}</h1>
          <p>
            {{ "HOME.HISTORY.ITEM2CONTENT" | translate }}
          </p>
        </div>
      </div>

      <!---->
    </div>

    <!---->
  </div>
</div>

<!--end History-->

<div class="main_end-wrapper">
  <!--Features-->

  <div class="main_features">
    <div class="main_container">
      <div class="main_features-title">
        <h1>{{ "HOME.FEATURES.TITLE" | translate }}</h1>
        <p>
          {{ "HOME.FEATURES.CONTENT" | translate }}
        </p>
      </div>

      <!-- Slider -->
      <div class="featureGrid">
        <div
          class="swiper-slide main_features-slider_content global_toggleable-button"
        >
          <div class="main_features-slider_content-wrapper active">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>

              <img src="assets/img/customs/bg/5_scarlet.webp" alt="" />
            </div>

            <div>
              <h1>{{ "HOME.FEATURES.ITEM1.TITLE" | translate }}</h1>
              <p>
                {{ "HOME.FEATURES.ITEM1.CONTENT" | translate }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="swiper-slide main_features-slider_content global_toggleable-button"
        >
          <div class="main_features-slider_content-wrapper active">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>

              <img src="assets/img/customs/bg/9_gnolls.webp" alt="" />
            </div>

            <div>
              <h1>{{ "HOME.FEATURES.ITEM2.TITLE" | translate }}</h1>
              <p>
                {{ "HOME.FEATURES.ITEM2.CONTENT" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="swiper-slide main_features-slider_content global_toggleable-button"
        >
          <div class="main_features-slider_content-wrapper active">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>

              <img src="assets/img/customs/bg/10_gilneas.webp" alt="" />
            </div>

            <div>
              <h1>{{ "HOME.FEATURES.ITEM3.TITLE" | translate }}</h1>
              <p>
                {{ "HOME.FEATURES.ITEM3.CONTENT" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end Features-->

    <!--Videos-->

    <div class="main_video">
      <div class="main_container global_tabs-container" data-tabset="video">
        <div class="main_video-title">
          <h1>{{ "HOME.VIDEOS.TITLE" | translate }}</h1>
        </div>

        <div class="main_video-select">
          <span
            class="global_tabs-button"
            data-tab="2"
            (click)="setActive('official')"
            [class.active]="activeSpan === 'official'"
            >{{ "HOME.VIDEOS.OFFICIAL" | translate }}</span
          >

          <!--
        <span
          class="global_tabs-button active"
          data-tab="1"
          (click)="setActive('community')"
          [class.active]="activeSpan === 'community'"
        >Community</span>
        --></div>

        <div
          id="main_video-community"
          class="main_video-list global_tabs global_list-loading active"
          data-items-per-page="3"
          data-tab="1"
          [class.active]="activeSpan === 'community'"
          #container
        >
          <ul class="global_list-loading_ul">
            <li class="global_list-loading_li">
              <a
                href="https://www.youtube.com/watch?v=tjOAKASoCEQ"
                data-fancybox="community"
              >
                <div>
                  <img src="assets/img/video/community/thumb_1.avif" />
                </div>
                <div>
                  <p>Duskhaven Reforged Gameplay ep.1</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li">
              <a
                href="https://www.youtube.com/watch?v=TjGJQDJEvig"
                data-fancybox="community"
              >
                <div>
                  <img src="assets/img/video/community/thumb_2.avif" />
                </div>
                <div>
                  <p>Duskhaven Gameplay ep.2</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li" style="display: none">
              <a
                href="https://www.youtube.com/watch?v=lDFn-XzIiq4"
                data-fancybox="community"
              >
                <div>
                  <img src="assets/img/video/community/thumb_3.avif" />
                </div>
                <div>
                  <p>How to start on Duskhaven</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li" style="display: none">
              <a
                href="https://www.youtube.com/watch?v=lDFn-XzIiq4"
                data-fancybox="community"
              >
                <div>
                  <img src="assets/img/video/community/thumb_3.avif" />
                </div>
                <div>
                  <p>How to start on Duskhaven</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li">
              <a
                href="https://www.youtube.com/watch?v=lDFn-XzIiq4"
                data-fancybox="community"
              >
                <div>
                  <img src="assets/img/video/community/thumb_3.avif" />
                </div>
                <div>
                  <p>How to start on Duskhaven</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>
          </ul>

          <!-- <div class="global_showMore">
          <button
            class="global_showMore-button"
            #showMoreButton
            (click)="handleShowMore()"
          >
            Show more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path
                d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
              />
            </svg>
          </button>

          <div class="global_spinner-loading" style="display: none"></div>
        </div> -->
        </div>

        <div
          id="main_video-official"
          class="main_video-list global_tabs global_list-loading"
          data-items-per-page="3"
          data-tab="2"
          [class.active]="activeSpan === 'official'"
          #container2
        >
          <ul class="global_list-loading_ul">
            <li class="global_list-loading_li">
              <a href="https://youtu.be/Cp2jJHTMQj0" data-fancybox="official">
                <div>
                  <img src="assets/img/video/official/1_zoneteaser.webp" />
                </div>
                <div>
                  <p>Revamped Zone Teaser</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li">
              <a href="https://youtu.be/K9SEehxHSZY" data-fancybox="official">
                <div>
                  <img src="assets/img/video/official/2_dusklogo.webp" />
                </div>
                <div>
                  <p>Lead Developer Roundtable Q&A</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li">
              <a href="https://youtu.be/Wn-ThD5ZwDU" data-fancybox="official">
                <div>
                  <img src="assets/img/video/official/2_dusklogo.webp" />
                </div>
                <div>
                  <p>Rare Mob Showcase</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>

            <li class="global_list-loading_li" style="display: none">
              <a href="https://youtu.be/ZxzhFBLvwyQ" data-fancybox="official">
                <div>
                  <img src="assets/img/video/official/3_charactercreate.webp" />
                </div>
                <div>
                  <p>Character Creation Showcase</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>
          </ul>

          <!-- <div class="global_showMore">
          <button
            class="global_showMore-button"
            (click)="handleShowMore2()"
            #showMoreButton2
          >
            Show more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path
                d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
              />
            </svg>
          </button>

          <div class="global_spinner-loading" style="display: none"></div>
        </div> -->
        </div>
      </div>
    </div>

    <!--end Videos-->
  </div>
</div>
