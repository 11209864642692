import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, take } from 'rxjs';
import { CartItem } from 'src/app/types/cartItem';
import { StoreItem } from 'src/app/types/storeItem';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-single-item',
  templateUrl: './single-item.component.html',
  styleUrl: './single-item.component.scss'
})
export class SingleItemComponent implements OnInit, OnDestroy {
  amount: number = 1;
  item: StoreItem | undefined;
  private subs = new SubSink();
  id = '';

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.fetchItem();
    });
  }

  fetchItem() {
    this.subs.sink = this.httpClient
      .get(environment.apiUrl + '/store/single?id=' + this.id, {
        withCredentials: true
      })
      .subscribe({
        next: response => {
          this.item = (response as any).item;
        },
        error: error => {
          console.log(error);
        }
      });
  }

  incrementAmount() {
    this.amount++;
  }

  decrementAmount() {
    this.amount--;
  }

  calculateTotal() {
    if (!this.item) {
      return;
    }

    return this.item.cost * this.amount;
  }

  addToCart() {
    if (!this.item) {
      return;
    }

    const cartItemToAdd: CartItem = {
      id: this.item.id,
      quantity: this.amount,
      title: this.item.title
    };

    const existingCartJSON = localStorage.getItem('cart');

    if (!existingCartJSON) {
      localStorage.setItem('cart', JSON.stringify([cartItemToAdd]));
      return;
    }

    const existingCart: CartItem[] = JSON.parse(existingCartJSON);
    const existingItemIndex = existingCart.findIndex(
      cartItem => cartItem.id === cartItemToAdd.id
    );
    if (existingItemIndex === -1) {
      existingCart.push(cartItemToAdd);
      localStorage.setItem('cart', JSON.stringify(existingCart));
      return;
    }

    existingCart[existingItemIndex].quantity += this.amount;
    localStorage.setItem('cart', JSON.stringify(existingCart));
    this.subs.sink = this.toastr
      .success('Added to Cart. Click here to go to cart', 'Success')
      .onTap.pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['/cart']);
      });

    return;
  }
}
