<div class="page_bg-map_3 page_bg"></div>
<div class="news">
  <!--page NavIndicator-->
  <ngx-scrolltop
    [backgroundColor]="'#292b40'"
    symbolColor="#ffd95a"
  ></ngx-scrolltop>
  <div class="main_container">
    <ul class="global_page-navIndicator">
      <li>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>

          {{ "BREADCRUMBS.MAIN" | translate }}
        </a>
      </li>

      <li>
        <a> </a>
      </li>
    </ul>
  </div>

  <!--end page NavIndicator-->

  <div class="news_title main_container issueHeading">
    <h1>{{ "BUGTRACKER.TITLE" | translate }}</h1>
    <p>
      {{ "BUGTRACKER.CONTENT" | translate }}
    </p>
  </div>

  <div class="issues_spacer"></div>

  <div class="issues_menu">
    <div class="main_container">
      <div class="filter_container">
        <div class="top_filter">
          <div class="top_filter__right">
            <a
              [ngClass]="currentTab === 'allissues' ? 'active' : ''"
              class="issueButton"
              (click)="setPageTab('allissues')"
              >{{ "BUGTRACKER.FILTERS.ALL_ISSUES" | translate }}</a
            >
            <a
              *ngIf="isLoggedIn === true"
              class="issueButton"
              [ngClass]="currentTab === 'myissues' ? 'active' : ''"
              (click)="setPageTab('myissues')"
              >{{ "BUGTRACKER.FILTERS.MY_ISSUES" | translate }}</a
            >
            <div class="search_input">
              <div class="global_searchingInput">
                <input
                  type="text"
                  id="global_inputSearch"
                  placeholder="Search"
                  (keyup)="onSearchInput($event)"
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>

                <span
                  id="global_searchingInput-loading"
                  style="display: none"
                ></span>
              </div>
            </div>
          </div>
          <div class="top_filter__left">
            <a class="issueButton" [routerLink]="['/issues/new']">
              {{ "BUGTRACKER.FILTERS.NEW" | translate }}
            </a>
          </div>
        </div>
        <div class="issue-tracker">
          <div class="issue-tracker--bar">
            <div
              [ngClass]="(issueDateRange === filter.category && issueState === filter.status) ? 'active' : ''"
              (click)="setFilter(filter.category, filter.status)"
              class="issue-tracker--pill"
              *ngFor="let filter of (filters$ | async)?.possibleFilters"
            >
              <div>
                <strong>{{ filter.count }}</strong> {{ filter.description }}
              </div>
              <div>
                {{
                  filter.category === "all_time" ? ("BUGTRACKER.FILTERS.ALL_TIME" | translate) : ("BUGTRACKER.FILTERS.LAST_WEEK" | translate) 
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="issueNav">
    <div class="issueArrows">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        (click)="changePage('decrement')"
        [ngClass]="currentPage === 1 ? 'fade' : ''"
      >
        <path
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        />
      </svg>
      <span class="curPage">{{ currentPage }} / {{maxPage}}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        (click)="changePage('increment')"
        [ngClass]="currentPage === maxPage ? 'fade' : ''"
      >
        <path
          d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
        />
      </svg>
    </div>
  </div>
  <table class="issuesTable">
    <tbody>
      <tr>
        <app-sort-header
          columnName="{{ 'BUGTRACKER.TABLE.PRIORITY' | translate }}"
          [sortColumn]="sortColumn"
          [sortDirection]="getCaretIcon('Priority')"
          (sortChange)="sortIssues($event)"
        ></app-sort-header>
        <app-sort-header
          columnName="{{ 'BUGTRACKER.TABLE.SUBJECT' | translate }}"
          [sortColumn]="sortColumn"
          [sortDirection]="getCaretIcon('Subject')"
          (sortChange)="sortIssues($event)"
        ></app-sort-header>
        <app-sort-header
          columnName="{{ 'BUGTRACKER.TABLE.CATEGORY' | translate }}"
          [sortColumn]="sortColumn"
          [sortDirection]="getCaretIcon('Category')"
          (sortChange)="sortIssues($event)"
        ></app-sort-header>
        <app-sort-header
          columnName="{{ 'BUGTRACKER.TABLE.STATUS' | translate }}"
          [sortColumn]="sortColumn"
          [sortDirection]="getCaretIcon('Status')"
          (sortChange)="sortIssues($event)"
        ></app-sort-header>
        <app-sort-header
          columnName="{{ 'BUGTRACKER.TABLE.LAST_UPDATED' | translate }}"
          [sortColumn]="sortColumn"
          [sortDirection]="getCaretIcon('Last_Updated')"
          (sortChange)="sortIssues($event)"
        ></app-sort-header>
      </tr>

      @for (issue of issues.issues; track $index) {
      <tr
        [routerLink]="['/issues/' + issue.issue_id.toString()]"
        [class.devcomment]="issue.isDev"
      >
        <td class="prio_{{ issue.Priority }}">
          {{ issue.Priority }}
        </td>
        <td>{{ issue.Subject }}</td>
        <td>{{ issue.Category }}</td>
        <td>{{ parseIssueState(issue.Status) }}</td>
        <td>{{ issue.Last_Updated | date : "MM/dd/yyyy" }}</td>
      </tr>
      }
    </tbody>
  </table>
  <div class="issueNav bottomNav">
    <div class="issueArrows">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        (click)="changePage('decrement')"
        [ngClass]="currentPage === 1 ? 'fade' : ''"
      >
        <path
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        />
      </svg>
      <span class="curPage">{{ currentPage }} / {{maxPage}}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        (click)="changePage('increment')"
        [ngClass]="currentPage === maxPage ? 'fade' : ''"
      >
        <path
          d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
        />
      </svg>
    </div>
  </div>
</div>
