<!--bg-->

<div class="page_bg-map_5 page_bg"></div>

<!--end bg-->

<!--Timeline-->

<div class="timeline global_tabs-container" data-tabset="timeline">
  <div class="main_container">
    <h1>{{ "TIMELINE.TITLE" | translate }}</h1>

    <div>
      <button class="global_tabs-button active" data-tab="2024">2024</button>
    </div>
  </div>
  <div id="timelineID" class="timeline_customwrapper">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let item of timelines?.data">
        <div
          class="timeline_content"
          [ngClass]="{
            released: item.attributes.Date
              ? hasPassed(item.attributes.Date)
              : ''
          }"
        >
          <div class="timeline_content-item">
            <div>
              <h1>{{ item.attributes.Title }}</h1>
              <p>{{ item.attributes.Subtitle }}</p>
            </div>

            <div>
              <!-- <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
              </a> -->

              <p>
                {{
                  item.attributes.Date
                    ? calculateDifference(item.attributes.Date)
                    : "TBD"
                }}
              </p>
              <img
                src="https://api.duskhaven.net/news/{{
                  item.attributes.Image.data.attributes.url
                }}"
                alt=""
              />
            </div>
          </div>

          <span></span>

          <span>
            {{ item.attributes.Date ? item.attributes.Date : "" }}
          </span>
        </div>
      </ng-template>
      <!-- <ng-template carouselSlide>
        <div class="timeline_content raid">
          <div class="timeline_content-item">
            <div>
              <h1>Raid</h1>
              <p>A new raid coming up and more content goes here and there</p>
            </div>

            <div>
              <a href="assets/img/shop/mount/1.jpg" data-fancybox="timeline">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
              </a>

              <p>TBR in 2 months</p>
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
          </div>

          <span></span>

          <span>
            December 25, 2023
          </span>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="timeline_content">
          <div class="timeline_content-item">
            <div>
              <h1>Raid</h1>
              <p>A new raid coming up</p>
            </div>

            <div>
              <a href="assets/img/shop/mount/1.jpg" data-fancybox="timeline">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
              </a>

              <p>TBR in 3 months</p>
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
          </div>

          <span></span>

          <span>
            December 25, 2023
          </span>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="timeline_content">
          <div class="timeline_content-item">
            <div>
              <h1>Raid</h1>
              <p>A new raid coming up</p>
            </div>

            <div>
              <a href="assets/img/shop/mount/1.jpg" data-fancybox="timeline">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
              </a>

              <p>TBA</p>
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
          </div>

          <span></span>

          <span>
            December 25, 2023
          </span>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="timeline_content">
          <div class="timeline_content-item">
            <div>
              <h1>Raid</h1>
              <p>A new raid coming up</p>
            </div>

            <div>
              <a href="assets/img/shop/mount/1.jpg" data-fancybox="timeline">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
              </a>

              <p>TBA</p>
              <img src="assets/img/shop/mount/1.jpg" alt="" />
            </div>
          </div>

          <span></span>

          <span>
            December 25, 2023
          </span>
        </div>
      </ng-template> -->
    </owl-carousel-o>
  </div>
</div>

<!--end Timeline-->
