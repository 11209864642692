<div class="itemGrid">
  <!-- <div class="itemRow">
        <fa-icon [icon]="faBook" />
        <h4>Scroll of Enchantment</h4>
        <p>Qty: 1</p>
        <button class="goldButton">Send</button>
    </div>
    <div class="itemRow">
        <fa-icon [icon]="faBook" />
        <h4>Scroll of Enchantment</h4>
        <p>Qty: 1</p>
        <button class="goldButton">Send</button>
    </div>
    <div class="itemRow">
        <fa-icon [icon]="faBook" />
        <h4>Scroll of Enchantment</h4>
        <p>Qty: 1</p>
        <button class="goldButton">Send</button>
    </div>
    <div class="itemRow">
        <fa-icon [icon]="faBook" />
        <h4>Scroll of Enchantment</h4>
        <p>Qty: 1</p>
        <button class="goldButton">Send</button>
    </div> -->
</div>
