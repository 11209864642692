import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { AuthService } from 'src/app/services/auth.service';
import { AccountInfo } from 'src/app/types/accountInfo';
import { httpError } from 'src/app/types/httpError';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('0ms', style({ opacity: 0 }))])
    ])
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  selectedOption: string = 'account';
  user = this.appState.userAccount$;
  accountId: string | undefined;
  userDropDown: boolean = false;
  displayName: string = '';
  mustSetDisplayName: boolean = false;

  displayNameForm = new FormGroup({
    displayName: new FormControl('')
  });

  private subs = new SubSink();

  constructor(
    private authService: AuthService,
    private router: Router,
    private httpClient: HttpClient,
    private viewContainerRef: ViewContainerRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private appState: AppStateService,
  ) {}

  ngOnInit(): void {
    this.accountId = sessionStorage.getItem('account_id')!;

    this.displayNameForm = this.formBuilder.group({
      displayName: ['', Validators.required]
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('setDisplayName') === 'setDisplayName') {
        this.mustSetDisplayName = true;
      }
    });
  }

  showModal(innerText: string, isSuccessful: boolean) {
    this.modalComponentRef = isSuccessful
      ? this.viewContainerRef.createComponent(SuccessComponent)
      : this.viewContainerRef.createComponent(ErrorComponent);
    this.modalComponentRef.instance.text = innerText;
    this.modalComponentRef.instance.confirmAction = isSuccessful
      ? () => {
          window.location.reload();
        }
      : () => {
          this.modalComponentRef?.instance.closeModal();
        };
    this.subs.sink = this.modalComponentRef.instance.closed.subscribe(() => {
      this.modalComponentRef?.destroy();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
    }
  }

  modalComponentRef:
    | ComponentRef<ErrorComponent>
    | undefined
    | ComponentRef<SuccessComponent>;


  logout() {
    this.authService.logout();
  }

  showComponent(option: string) {
    this.selectedOption = option;
  }

  get f() {
    return this.displayNameForm.controls;
  }

  setDisplayName() {
    if (this.displayNameForm.invalid || this.accountId === undefined) {
      return;
    }

    const reqBody = {
      user_id: this.accountId,
      displayName: this.f['displayName'].value
    };

    this.subs.sink = this.httpClient
      .post(environment.apiUrl + '/points/setDisplayName', reqBody, {
        withCredentials: true
      })
      .subscribe(
        response => {
          const msg: httpSuccess = response as any;
          this.showModal('Successfully changed display name', true);
          this.appState.fetchUser();
        },
        error => {
          const err: HttpErrorResponse = error;
          let msg = err.message;
          if (err.status === 401) {
            const e: httpError = err.error;
            msg = e.message;
          }

          this.showModal(msg, false);
        }
      );
  }
}
