import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './success.component.html',
  styleUrl: './success.component.scss'
})
export class SuccessComponent {
  @Output() closed = new EventEmitter<void>();
  @Input() text: string = '';
  @Input()
  confirmAction: () => void = () => {};

  closeModal() {
    this.closed.emit();
  }
}
