import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreItem, StoreItemResponse } from 'src/app/types/storeItem';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrl: './store.component.scss'
})
export class StoreComponent implements OnInit, OnDestroy {
  public currentView: string = 'All';
  items: StoreItem[] = [];

  private subs = new SubSink();

  constructor(private httpClient: HttpClient) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public changeView(nextView: string) {
    this.currentView = nextView;
    console.log(this.currentView);
  }

  ngOnInit(): void {
    this.fetchItems();
  }

  fetchItems() {
    this.subs.sink = this.httpClient
      .get(environment.apiUrl + '/store/all', { withCredentials: true })
      .subscribe({
        next: response => {
          const body: StoreItemResponse = response as any;
          this.items = body.items;
        },
        error: error => {
          console.log(error);
        },
        complete: () => {}
      });
  }

  getItemsByCategory(category: string) {
    return this.items.filter(item => item.category === category);
  }
}
