import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { httpError } from 'src/app/types/httpError';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nameres',
  templateUrl: './nameres.component.html',
  styleUrls: ['./nameres.component.scss']
})
export class NameresComponent implements OnInit, OnDestroy {
  nameForm = new FormGroup({
    name: new FormControl('')
  });
  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.nameForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get f() {
    return this.nameForm.controls;
  }

  reserve() {
    if (this.nameForm.invalid) {
      return;
    } else {
      const requestBody = {
        id: sessionStorage.getItem('account_id'),
        name: this.f.name.value
      };

      this.subs.sink = this.httpClient
        .post(environment.apiUrl + '/characters/reserve', requestBody, {
          withCredentials: true
        })
        .subscribe(
          request => {
            const res: httpSuccess = request as any;
          },
          error => {
            const err: HttpErrorResponse = error;
            console.log(error);
            const apiError: httpError = err.error;
          }
        );
    }
  }
}
