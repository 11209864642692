<div class="accountGrid">
  <div class="detailRow">
    <h4>Username:</h4>
    <p>{{ user()?.username }}</p>
  </div>
  <div class="detailRow">
    <h4>Email:</h4>
    <p>{{ user()?.email }}</p>
  </div>
  <div class="detailRow">
    <h4>Points:</h4>
    <p>{{ user()?.donation_points }}</p>
  </div>
</div>
