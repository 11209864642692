<!--bg-->

<div class="page_bg-map_1 page_bg"></div>

<!--end bg-->

<!--Global centered Form-->

<div class="global_centered-form">
  <div class="main_container">
    <form class="global_form comingSoon">
      <img src="assets/img/logo/logo.png" alt="" />
      <h1 class="maintenance-heading">Coming Soon!</h1>
      <p class="maintenance-text">
        This page is currently under Maintenance or it's currently disabled until PTR is released. Please check back later and join our Discord for updates!
      </p>

      <a href="https://discord.gg/duskhaven" class="discord-button">
        <img src="assets/img/logo/discord.webp" alt="Join Now!" />
      </a>
    </form>
  </div>
</div>

<!--end Global centered Form-->
