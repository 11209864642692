import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { httpError } from 'src/app/types/httpError';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {
  forgotForm = new FormGroup({
    email: new FormControl('')
  });
  private subs = new SubSink();

  isButtonDisabled: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private httpClient: HttpClient,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get f() {
    return this.forgotForm.controls;
  }

  forgotPassword() {
    this.isButtonDisabled = true;
    if (this.forgotForm.invalid) {
      this.isButtonDisabled = false;
      return;
    } else {
      const requestBody = {
        email: this.f['email'].value
      };

      this.subs.sink = this.httpClient
        .post(environment.apiUrl + '/users/forgot', requestBody, {
          withCredentials: true
        })
        .subscribe(
          response => {
            const res: httpSuccess = response as any;
            this.toastr.success('Check your email for further instructions.',"Success!")
            this.isButtonDisabled = false;
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 7000);
          },
          error => {
            const err: HttpErrorResponse = error;
            let msg = err.message;
            if (err.status === 401) {
              const e: httpError = err.error;
              msg = e.message;
            }
            this.toastr.error(`The email you've entered does not exist. Please try again`, "Error!")

            console.log(error);
            this.isButtonDisabled = false;
          }
        );
    }
  }
}
