import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { httpError } from 'src/app/types/httpError';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-redeem-code',
  templateUrl: './redeem-code.component.html',
  styleUrls: ['./redeem-code.component.scss']
})
export class RedeemCodeComponent implements OnInit, OnDestroy {
  voucherForm = new FormGroup({
    voucherCode: new FormControl('')
  });
  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.voucherForm = this.formBuilder.group({
      voucherCode: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get f() {
    return this.voucherForm.controls;
  }

  reserve() {
    if (this.voucherForm.invalid) {
      return;
    } else {
      const requestBody = {
        id: sessionStorage.getItem('account_id'),
        voucherCode: this.f.voucherCode.value
      };

      this.subs.sink = this.httpClient
        .post(environment.apiUrl + '/vouchers/redeem', requestBody, {
          withCredentials: true
        })
        .subscribe(
          request => {
            const res: httpSuccess = request as any;
          },
          error => {
            const err: HttpErrorResponse = error;
            console.log(error);
            const apiError: httpError = err.error;
          }
        );
    }
  }
}
