import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/Home/home.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { LoginComponent } from './pages/login/login.component';
import { AppComponent } from './pages/app/app.component';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AccountComponent } from './pages/dashboard/account/account.component';
import { NameresComponent } from './pages/dashboard/nameres/nameres.component';
import { ItemsComponent } from './pages/dashboard/items/items.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RedeemCodeComponent } from './pages/dashboard/redeem-code/redeem-code.component';
// import { CursorComponent } from './shared/cursor/cursor.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ResetComponent } from './pages/reset/reset.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { TosComponent } from './pages/legal/tos/tos.component';
import { PrivacypolicyComponent } from './pages/legal/privacypolicy/privacypolicy.component';
import { RefundpolicyComponent } from './pages/legal/refundpolicy/refundpolicy.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NewsComponent } from './pages/news/news.component';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { HowToConnectComponent } from './pages/how-to-connect/how-to-connect.component';
import { DatePipe } from '@angular/common';
import { SingleIssueComponent } from './pages/issues/single-issue/single-issue.component';
import { NewIssueComponent } from './pages/issues/new-issue/new-issue.component';
import { CustomComponent } from './pages/custom/custom.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ActivateComponent } from './pages/activate/activate.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { StoreComponent } from './pages/store/store.component';
import { SingleItemComponent } from './pages/store/single-item/single-item.component';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { DonateComponent } from './pages/donate/donate.component';
import { LogsComponent } from './pages/dashboard/logs/logs.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    AppComponent,
    RegisterComponent,
    DashboardComponent,
    AccountComponent,
    NameresComponent,
    ItemsComponent,
    RedeemCodeComponent,
    // CursorComponent,
    ComingSoonComponent,
    ResetComponent,
    ForgotComponent,
    TosComponent,
    PrivacypolicyComponent,
    RefundpolicyComponent,
    NewsComponent,
    ChangelogComponent,
    TimelineComponent,
    HowToConnectComponent,
    SingleIssueComponent,
    NewIssueComponent,
    CustomComponent,
    ActivateComponent,
    StoreComponent,
    SingleItemComponent,
    DonateComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterLink,
    RouterOutlet,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    CarouselModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true,
        },
      },
    }),
    NgxScrollTopModule,
    CKEditorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: environment.language,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LogsComponent,
  ],
  providers: [DatePipe],
  bootstrap: [HeaderComponent, FooterComponent, AppComponent],
})
export class AppModule {}
