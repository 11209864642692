<!--bg-->

<div class="page_bg-map_1 page_bg"></div>

<!--end bg-->

<!--Global centered Form-->

<div class="global_centered-form">
  <div class="main_container">
    <form
      class="global_form issueForm"
      [formGroup]="issueForm"
      (submit)="submitIssue()"
    >
      <div class="global_form-heading">
        <img src="assets/img/logo/logo.png" alt="" />
        <h1>New Issue</h1>
      </div>

      <div class="global_form-input issueInput">
        <input
          type="text"
          placeholder="Title"
          formControlName="title"
          required
        />

        <span class="error">!</span>
      </div>

      <div class="global_form-input issueInput">
        <select name="Category" formControlName="category">
          <option *ngFor="let cat of categories">{{ cat }}</option>
        </select>
      </div>

      <div class="global_form-input issueInput">
        <select name="Priority" formControlName="priority">
          <option *ngFor="let prio of priority">{{ prio }}</option>
        </select>
      </div>

      <div class="global_form-input issueInput">
        <!-- <textarea
          placeholder="Description of Issue"
          formControlName="description"
        ></textarea> -->
        <ckeditor [editor]="Editor" (change)="setDesc($event)" [style]="{}">
        </ckeditor>
      </div>

      <div class="global_form-button">
        <button type="submit">Confirm</button>
      </div>
    </form>
  </div>
</div>

<!--end Global centered Form-->
