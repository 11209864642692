<div class="legalWrapper">
  <h1>Terms of Service</h1>
  <h2>Duskhaven Terms of Service Agreement</h2>

  <h4>"User"</h4>
  <p>
    refers to any individual who accesses or interacts with the Site and its
    Services, irrespective of whether they are registered with Duskhaven
    Reforged.
  </p>

  <h4>"Member"</h4>
  <p>
    refers to an individual who has registered an account with Duskhaven
    Reforged.
  </p>

  <h4>"Site"</h4>
  <p>refers to the Duskhaven website and its associated web pages.</p>

  <h4>"Services"</h4>
  <p>
    refers to the features, content, and applications offered by Duskhaven
    Reforged.
  </p>

  <h4>Acceptance of Terms</h4>
  <p>
    By accessing, browsing, or otherwise using the Site and its Services, the
    User agrees to comply with this Terms of Service Agreement (hereafter
    referred to as "TOS").
  </p>

  <h4>Disclaimer on Game Client Modifications</h4>
  <p>
    Duskhaven expressly disclaims any support for modifications to the
    original game client. Users and Members are advised that such modifications
    may be against the original game's End User License Agreement (EULA).
  </p>

  <h4>Description of Website Services Offered</h4>
  <p>
    The Site is operated as a non-profit educational project that aims to
    explore the gaming environment and mechanics. As such, the Services do not
    support and do not provide any modification to original game files.
  </p>

  <h4>Membership Account, Username, Password, and Security</h4>
  <p>
    When a Member sets up an account, they are the sole authorized user of that
    account. The Member shall be responsible for maintaining the confidentiality
    of their password and for all activities that occur under their account. Any
    failure to comply with this term may result in termination of the account
    (Refer to our Code of Conduct for more details).
  </p>

  <h4>Usage of Content</h4>
  <p>
    The content made available on the Site is the sole property of Duskhaven
    Reforged. Members may use this content provided they attribute proper credit
    to Duskhaven. Unauthorized use of this content is expressly
    prohibited.
  </p>

  <h4>Indemnity</h4>
  <p>
    The User agrees to indemnify and hold harmless Duskhaven, its
    subsidiaries, affiliates, agents, employees, officers, and partners from any
    claim or demand, including reasonable legal fees, made by any third party in
    relation to or arising out of the User's conduct, use of Services, violation
    of these TOS, or violation of any rights of another person or entity.
  </p>

  <h4>Warranty Disclaimers</h4>
  <p>
    The User acknowledges that the Services are provided "AS IS" and "AS
    AVAILABLE". Duskhaven makes no representations or warranties of any
    kind, express or implied, including but not limited to, the implied
    warranties of merchantability, fitness for a particular purpose, and
    non-infringement. Duskhaven does not warrant that the Services will
    be uninterrupted, secure, or error-free, nor does it make any warranty as to
    the results that may be obtained from the use of the Services.
  </p>

  <h4>Limitation of Liability</h4>
  <p>
    To the fullest extent permissible by applicable law, Duskhaven
    shall not be liable for any indirect, incidental, special, consequential, or
    exemplary damages, including but not limited to damages for loss of profits,
    goodwill, or other intangible losses (even if Duskhaven has been
    advised of the possibility of such damages), arising out of or relating to
    the access or use, or inability to access or use, the Services.
  </p>

  <h4>Termination</h4>
  <p>
    Duskhaven reserves the right to change, suspend, or discontinue any
    aspect of the Services at any time. Access to the Services may be terminated
    at any time for any reason (Refer to our Code of Conduct for more details).
  </p>

  <h4>Donations</h4>
  <p>
    Donations to Duskhaven are voluntary and solely for the betterment
    of the Site and Services. Members must be 18 years or older to make a
    donation (Refer to our Refund Policy for more details).
  </p>

  <h4>Violations</h4>
  <p>
    Any violations of this TOS should be reported to a staff member of Duskhaven
    Reforged.
  </p>
</div>
